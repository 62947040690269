export const REGISTER_ERROR_INVALID_CREDENTIALS = 'InvalidCredentials';
export const REGISTER_ERROR_INVALID_BIRTHDAY = 'InvalidBirthday';
export const REGISTER_ERROR_INVALID_USERNAME = 'InvalidUsername';
export const REGISTER_ERROR_INVALID_EMAIL = 'InvalidEmail';
export const REGISTER_ERROR_USER_CREATED = 'UserCreated';
export const REGISTER_ERROR_UNEXPECTED_ERROR = 'UnexpectedError';

export const DEFAULT_SIMPLE_BIRTH_DATE = "1800-01-01";
export const SIGNUP_MODE_SIMPLE = 'simple';
export const SIGNUP_MODE_BY_AGE = 'byAge';
