import React from "react";
import {Row, Col} from 'reactstrap';

import AvatarComponent from "./components/AvatarComponent";
import UserRankingComponent from "./components/UserRankingComponent";

const UserInfoComponent = ({userAvatar, userRanking, closestAchievement, userName}) => {
    return (
        <Row>
            <Col xs="5"  className="Avatar">
                <AvatarComponent userAvatar={userAvatar} userRanking={userRanking} userName={userName}/>
            </Col>
            <Col xs="7" className={"User-info-cards"}>
                <UserRankingComponent userRanking={userRanking} userName={userName}
                                      closestAchievement={closestAchievement}/>
            </Col>
        </Row>
    )
};

export default UserInfoComponent;