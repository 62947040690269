import React from 'react';
import diplomaIcon from '@app/Resources/Images/scroll.svg';
import './DiplomaComponent.css';

const DiplomaIconComponent = () => {

    return (
        <div className={"DiplomaContainer"}>
            <img src={diplomaIcon} alt={'Diploma-Icon'} className={"DiplomaIcon"}/>
        </div>
    )
};

export default DiplomaIconComponent;