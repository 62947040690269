import Keycloak from "keycloak-js";
import { coreBuild } from "../../core-framework";

const keycloakConfig = {
  realm: coreBuild.envConfig.keycloakRealm,
  url: coreBuild.envConfig.keycloakBaseUri,
  clientId: coreBuild.envConfig.keycloakClientId,
};

const keycloak = new Keycloak(keycloakConfig);

export const hasRole = (role) => (!!keycloak ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role) : false);

export const hasRoles = (roles) =>
  !!keycloak ? roles.some((r) => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r)) : false;
export default keycloak;
