import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {
    URL_HOME,
    URL_NAME_PUBLISH,
    URL_NAME_REJECT,
    URL_VIDEODETAIL
} from "../../../../app/src/Components/Routes/Location";
import {useHistory} from "react-router-dom";
import {
    turnOffLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import {user} from '@core'
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import './PublishRejectStyles.css';
import logoPetisgo from "@resources/Images/logoPetiscos1444-512px.png";
import cornerTop from "@resources/Images/fondo onboarding_der.png";
import cornerBottom from "@resources/Images/fondo onboarding_izq.png";
import rejectIcon from "@resources/Images/semaforo luz roja.svg";
import publishIcon from "@resources/Images/semaforo luz verde.svg";
import {useQuery} from "../../../../app/src/Utils/utils";

const PublishRejectComponent = ({event, userAppController}) => {

    const outputFn = useOutput();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const publishRejectObject = {
        [URL_NAME_PUBLISH] : {
            'title': t('user.publish.title'),
            'img': publishIcon,
            'text': t('user.publish.text'),
        },
        [URL_NAME_REJECT] : {
            'title': t('user.reject.title'),
            'img': rejectIcon,
            'text': t('user.reject.text'),
        },
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    useEffect(() => {
        turnOffLoading();
        const con = userAppController;
        let binding = con.bind(model, o => outputFn(o, output, user));
        const ev = new user.events.PublishContent(contentId, payload)
        con.dispatch(ev);

        return () => {
            con.unbind(binding)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    const model = (o) => {
    }

    const output = (o) => {
    }

    let {contentId} = useParams();

    let query = useQuery();
    let payload=query.get("payload");

    const handleRedirectToHome = () => {
        history.push(URL_HOME);
    }
    const handleRedirectToPlayer = () => {
        history.push(`${URL_VIDEODETAIL}/${contentId}`);
    }


    return <div className={'publishReject'}>
            <div className={'logoApp'} >
                <img src={logoPetisgo} alt={"logo-Petisgo"} className={"logoPetisgo"}/>
            </div>
            <div className={'cornerTop'} >
                <img src={cornerTop} alt={'Corner'}/>
            </div>
            <div className={'content'}>
                <div className={'iconAlert'}><img src={publishRejectObject[event].img} alt={'Alert icon'}/></div>
                <div className={'text'}>
                    <h1>{publishRejectObject[event].title}</h1>
                    <p>{publishRejectObject[event].text}</p>
                    <div className="button-group">
                        <button type="button" onClick={handleRedirectToHome} className="button-default-publish">
                            <span className="button-text">{t('user.reject.go.home')}</span>
                        </button>
                        {event === URL_NAME_PUBLISH &&
                            <button type="button" onClick={handleRedirectToPlayer} className="button-default-invert-publish">
                                <span className="button-text">{t('user.reject.go.content')}</span>
                            </button>
                        }
                    </div>
                </div>
                <div className={'cornerBottom'} >
                    <img src={cornerBottom} alt={'Corner'}/>
                </div>
           </div>
          </div>
};

export default PublishRejectComponent;