import React from 'react';
import {useTranslation} from 'react-i18next';

import {ROL_KID, ROL_TEEN} from './PantallasRexistro';
import iconoTitor from '@app/Resources/Images/Eye.svg'
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";

function CTitor(props) {
    const {t} = useTranslation();

    return (
        <div className="CTitor">
            <div className="WizardContainer">
                <div className="Descricion">
                    {t('register.chamatitor')}
                    <div className={"subTitle"}>{t('register.textochamartitor')}</div>
                </div>
                <div className="IconoTitor">
                    {props.datos.rol === ROL_KID &&
                    <img src={iconoTitor} alt={t('register.textochamartitor')}/>
                    }
                    {props.datos.rol === ROL_TEEN &&
                    <img src={iconoTitor} alt={t('register.textochamartitor')}/>
                    }
                </div>
            </div>
            <RegisterNavigationComponent handlerBeforePage={props.anteriorPantalla} handlerNextPage={props.seguintePantalla} />
        </div>
    );
}

export default CTitor;
