import Keycloak from "keycloak-js";
import { coreBuild } from "../../../core-framework";

export const defaultKeycloak = new Keycloak({
  realm: coreBuild.envConfig.keycloakRealm,
  url: coreBuild.envConfig.keycloakBaseUri,
  clientId: coreBuild.envConfig.keycloakClientId,
});

export var createKeycloak = (url, realm, clientId) =>
  new Keycloak({
    realm: realm,
    url: url,
    clientId: clientId,
    "enable-cors": true,
  });
