import React from "react";
import '../../Ranking.css';

const MobileMenuComponent = ({renderList, handleSetRenderList}) => {


    return (
        <div className={"Mobile-menu"}>
            <button className={renderList ? "Mobile-menu-item-selected" : "Mobile-menu-item"} onClick={() => handleSetRenderList(true)}>ranking</button>
            <button className={renderList ? "Mobile-menu-item" : "Mobile-menu-item-selected"} onClick={() => handleSetRenderList(false)}>logros</button>
        </div>)
};

export default MobileMenuComponent;