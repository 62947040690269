import React, {useState} from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import {createKeycloak} from '@components/Keycloak/Keycloak';

import icono from '@app/Resources/Images/Icon_child.png';
import './SelectAge.css';

function SelectAge(props) {
    const [availableLoginClients, setAvailableLoginClients] = useState(null);
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();

    function getModel(m){
        if(m.availableLoginClients){
            var loginClients = {}

            m.availableLoginClients.toArray().forEach((lc) => {
                var kc = null;
                if(lc.auth){
                    kc = createKeycloak(lc.auth.baseUri, lc.auth.realm, lc.auth.clientId)
                }

                loginClients[lc.name] = kc;
            })
            setAvailableLoginClients(loginClients)
        }
    }

    function getOutput(o){
        console.log(o)
    }

    //Comprobar sesion xa iniciada
    if (keycloak){
        if(keycloak.authenticated){
            return <Redirect to={'/home'} />
        }
    }

    //Actualizar keycloak
    function useKeyCloakChild(){
        props.changekeycloak(availableLoginClients["petisgo-kids-web"]);
    }

    function useKeyCloakAdult(){
        props.changekeycloak(availableLoginClients["petisgo-default-web"]);
    }

    return (
        <div className="SelectAge App-fullscreen">
            <div>
                <h1>{t('splash.hola')}</h1>
                <h2>{t('splash.confirmaridade')}</h2>
                <img src={icono} className="App-icon" alt="logo" />
            </div>

            <NavLink to={'./login'} onClick={useKeyCloakChild} className="selector-idade">{t('splash.entre6y9')}</NavLink>
            <NavLink to={'./login'} onClick={useKeyCloakChild} className="selector-idade">{t('splash.entre10y13')}</NavLink>
            <NavLink to={'./login'}  onClick={useKeyCloakAdult} className="selector-idade">{t('splash.mas14')}</NavLink>
        </div>
    );
}

export default SelectAge;
