import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import 'moment/locale/gl';

import ButtonEllipsis from "@detail/Pages/DetailPage/ButtonEllipsis";

import './Comments.css';

function Comments(props) {
    const {t} = useTranslation();
    const [comment, setComment] = useState(null);
    const [commentEdit, setCommentEdit] = useState(null);

    const [edit, setEdit] = useState(false);
    const [index, setIndex] = useState(false);

    const handleclickSend = () => {
        if (comment !== null && comment !== "") {
            var add = new props.events.AddComment(comment);
            props.controller?.dispatch(add);
            setComment("");
        }
    }

    const handleclickEdit = (idComment) => {
        if (commentEdit !== null && commentEdit !== "") {
            var add = new props.events.UpdateComment(idComment, commentEdit);
            props.controller?.dispatch(add);
        }
        setCommentEdit("")
        setEdit(false)
    }

    const handleSubmit = (event) => {
        if (event.target !== undefined) {
            setComment(event.target.value);
        }
    }

    const handleChangeEdit = (event) => {
        if (event.target !== undefined) {
            setCommentEdit(event.target.value);
        }
    }

    const handleEdit = (index) => {
        setEdit(true)
        setIndex(index)
    }

    return (
        <div className="Comments">
            <div className="Comment2">
                {props.userName &&
                <div className="NewComent">
                    <div className="UsuarioComent">
                        {props.avatar &&
                        <img className="AvatarComent" src={props.avatar} alt="Usuario"/>
                        }
                    </div>

                    <div className="ImputComment">
                        <input className="InputName" type="text"
                               placeholder={props.placeholder ? props.placeholder : ""}
                               onChange={(comment) => handleSubmit(comment)} value={comment ? comment : ""}/>
                        <input className="InputButton button button-focused CommentButton"
                               type="button" onClick={handleclickSend} value={t('ficha.send')}/>
                    </div>
                </div>}

                {props.comments?.map((c) =>
                    <div className="CommentsUsers" key={c.id}>
                        <div className="TopBarComment">
                            <div className="ItemTitle">{c.user}</div>
                            <div
                                className="PublishDate">{c.publishDate ? moment(c.publishDate)?.format("DD MMM YYYY") : ""}</div>
                            <div className="button2">
                                {props.userName === c.user &&
                                <ButtonEllipsis
                                    id={c.id}
                                    events={props}
                                    editCommmets={handleEdit}
                                    className={"EditButton"}/>}
                            </div>
                        </div>

                        <div className="ContentComment">
                            {(!edit || c.id !== index) &&
                            <div className="ItemSubtitle">{c.text}</div>}
                            {edit && c.id === index &&
                            <div>
                                <div className="ImputCommentEdit">
                                    <input className="ItemSubtitleEdit" type="text"
                                           onChange={(commentEdit) => handleChangeEdit(commentEdit)}
                                           defaultValue={c.text}/>
                                </div>

                                <div className="CommentEditButton">
                                    <input type="button" className="button button-focused"
                                           onClick={(idComment) => handleclickEdit(c.id)}
                                           value={t('ficha.edit')}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                )}
                {props.areMoreComments ? <div className="ShowMoreButton">
                    <input type="button" className="button-link-secondary"
                           onClick={() => props.controller.dispatch(props.events.LoadMoreComments)}
                           value={t('ficha.showmore')}/>
                </div> : <></>}

            </div>
        </div>
    );
}

export default Comments;
