import {
  DISPLAY_SESSION_EXPIRED_MODAL,
  HIDE_SESSION_EXPIRED_MODAL,
  TURN_OFF_LOADING_ACTION,
  TURN_ON_LOADING_ACTION,
  LOAD_TENANT_STYLES,
  STORE_PRODUCT_URL,
  TOGGLE_PASSWORD_MODE,
  DISPLAY_ON_BOARDING,
  SELECT_MENU_SECTION,
  STORE_HOME_SECTION,
} from "../actions/actionTypes";

const skipOnBoarding = localStorage.getItem("petisgo-skip-onboarding");

const INITIAL_STATE = {
  loading: true,
  isSessionExpired: false,
  tenantStyles: {},
  productURL: null,
  passwordMode: false,
  onBoarding: JSON.parse(skipOnBoarding),
  sectionSelected: null,
  homeSection: null,
};

function commonState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TURN_ON_LOADING_ACTION: {
      return { ...state, loading: true };
    }
    case TURN_OFF_LOADING_ACTION: {
      return { ...state, loading: false };
    }
    case DISPLAY_SESSION_EXPIRED_MODAL: {
      return { ...state, isSessionExpired: true };
    }
    case HIDE_SESSION_EXPIRED_MODAL: {
      return { ...state, isSessionExpired: false };
    }
    case LOAD_TENANT_STYLES: {
      return { ...state, tenantStyles: action.data };
    }
    case STORE_PRODUCT_URL: {
      return { ...state, productURL: action.data };
    }
    case TOGGLE_PASSWORD_MODE: {
      return { ...state, passwordMode: !state.passwordMode };
    }
    case DISPLAY_ON_BOARDING: {
      return { ...state, onBoarding: action.data };
    }
    case SELECT_MENU_SECTION: {
      return { ...state, sectionSelected: action.data };
    }
    case STORE_HOME_SECTION: {
      return { ...state, homeSection: action.data };
    }
    default:
      return state;
  }
}

export default commonState;
