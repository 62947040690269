import React from "react";
import './ReportContent.css';
import {useTranslation} from 'react-i18next';
import HandleClickOutside from "../../../../app/src/Components/DOM/Click/HandleClickOutside";


const ReportContentModalComponent = ({
                                         refOptions, availableReportReasons, reasonDescription, handleSetReasonDescription,
                                         hideOptions, reason, handleSetReason, handleReportContent, handleCloseModal, sentMessage
                                     }) => {
    const {t} = useTranslation();

    return (
        <div className={"ModalBackground"}>
            <HandleClickOutside elementref={refOptions} callback={hideOptions}/>
            <div className="ReportContentOptions" ref={refOptions}>
                {sentMessage ?
                    <div className="ReportContentSentMessage">
                        <div className="SentMessageContainer">
                            <p className="ReportSubtitle">{t("reportcontent.sent_message1")}</p>
                            <p className="ReportSubtitle">{t("reportcontent.sent_message2")}</p>
                        </div>
                        <button onClick={() => handleCloseModal()}
                                className="button-focused">{t("reportcontent.ok")}</button>
                    </div>
                    : <div>
                        <p className="ReportTitle">{t("reportcontent.reason")}</p>
                        <p className="ReportSubtitle">{t("reportcontent.reason.subtitle")}</p>
                        <div className="ReportOptionContainer">
                            {availableReportReasons && availableReportReasons?.map((p) =>
                                <div className="ReportOption"
                                     key={p.name} onClick={() => handleSetReason(p)}>
                                    <label className={"container"}>
                                        <input className="ReportRadioButton" type="radio"
                                               onClick={() => handleSetReason(p)}
                                               checked={reason?.name === p.name}/>
                                        <span className={"checkmark"}/>
                                        <p className={"checkmark-label"}>{t(p.name)}</p>
                                    </label>
                                </div>)}
                        </div>
                        <form>
                            <label className="ReportOptionTitle">
                                <p className="ReportSubtitle">{t("reportcontent.description")}</p>
                                <textarea className="ReportOptionInput" value={reasonDescription}
                                          onChange={handleSetReasonDescription}
                                          placeholder={t("reportcontent.placeholder")}/>
                            </label>
                            <div className="CheckoutButtonContainer">
                                <button onClick={() => hideOptions()}
                                        className="ReportOptionButton button-focused">{t('reportcontent.cancel')}</button>
                                <button onClick={() => handleReportContent()} disabled={reason === null}
                                        className="button-focused">{t('reportcontent.send')}</button>
                            </div>
                        </form>
                    </div>}
            </div>
        </div>
    )
};

export default ReportContentModalComponent;