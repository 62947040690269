import React from "react";
import { Link } from "react-router-dom";

import "./Breadcrumb.css";

/**
 * Create a breadcrumb from an element array.
 * Each element is an object and can have next attributes:
 * - text: text to display in breadcrumb
 * - url: link to redirect on press breadcrumb. Exclusive with onclick (Optional)
 * - onclick: function to call when the breadcrumb is pressed. Exclusive with url (Optional)
 *
 * @param breadcumbs item array
 */
function Breadcrumb(props) {
  return (
    <div className="Breadcrumbs">
      {props.breadcrumbs &&
        props.breadcrumbs.map((m) => (
          <div className="Breadcrumb" key={m.text}>
            {m.url && (
              <Link className="BreadcumbLink" to={m.url}>
                {m.text}
              </Link>
            )}
            {!m.url && m.onclick && (
              <div className="BreadcumbLink" onClick={m.onclick}>
                {m.text}
              </div>
            )}
            {!m.url && !m.onclick && <div className="BreadcumbFinal">{m.text}</div>}
          </div>
        ))}
    </div>
  );
}

export default Breadcrumb;
