import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useDebounce } from "use-debounce";
import {ROL_KID, ROL_TEEN} from './PantallasRexistro';
import ReactDice from 'react-dice-complete';
import 'react-dice-complete/dist/react-dice-complete.css'
import AlertNotification from "@components/GUI/Alert/Alert";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";
import { signup } from '@core'
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {WITH_SPACES} from "../../../../app/src/Utils/Constants";

export const invalidCharacter = 'Ñ';

function NameUser(props) {

    const {ctrlSetUsername, invalidUsername} = props;
    const outputFn = useOutput();
    const [controller, setController] = useState(null);
    const [nomeRandom, setNomeRandom] = useState(null);
    const [nome, setNome] = useState("");
    const [invalido, setInvalido] = useState(false);
    const {t} = useTranslation();



    const events = window.core.es.cinfo.tiivii.user.signup.view.SignupView.Event;
    let reactDice;

    //Obter cliente por defecto
    useEffect(() => {
        const control = new signup.controller;
        let binding = control.bind(handleModel, o => outputFn(o, handleOutput, signup))
        setController(control)
        if (!props.datos.username) {
            const evRoll = events.RollUsername;
            control.dispatch(evRoll)
        } else {
            setNomeRandom(props.datos.username)
        }
        if (props.datos.username) {
            setNome(props.datos.username)
        }

        /*return () => {
            con.unbind(binding)
        }*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleModel = (o) => {
        if (o.randomUsername) {
            setNomeRandom(o.randomUsername)
            let d = props.datos;
            d.username = o.randomUsername;
            props.actualizarDatos(d)
        }

        if (o.username) {
            let d = props.datos;
            d.username = o.username;
            props.actualizarDatos(d)
            props.seguintePantalla();
        }
    }

    const handleOutput = (o) => {}


    const [textToDispache] = useDebounce(nome, 300);


    useEffect(() => {
        ctrlSetUsername(textToDispache);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[textToDispache]);

    const handleChangeNome = (e) => {
        const userName = e.target.value
        const withSpace = /\s/;
        if(userName.toUpperCase().includes(invalidCharacter) || withSpace.test(userName)  ) {
            setInvalido(true);
        } else {
            setInvalido(false);
        }
        setNome(userName);
    }

    const xerarNovoNome = () => {
        const evRoll = events.RollUsername;
        controller.dispatch(evRoll)
        setInvalido(false);
    }

    const validar = () => {
        const d = props.datos;
        if (props.datos.rol === ROL_KID) {
            if (nomeRandom) {
                ctrlSetUsername(nomeRandom)
                d.username = nomeRandom;
                props.actualizarDatos(d);
                props.seguintePantalla();
                //let ev = new events.SetUsername(nomeRandom);
                //controller.dispatch(ev);
            }
        }
        if (props.datos.rol === ROL_TEEN) {
            if (nome && nome.length > 0) {
                setInvalido(false)
                ctrlSetUsername(nome)
                d.username = nome;
                props.actualizarDatos(d);
                props.seguintePantalla();
                //let ev = new events.SetUsername(nome);
                //controller.dispatch(ev)
            } else {
                setInvalido(true)
            }
        }
    }

    const rollAll = () => {
        reactDice.rollAll()
    }


    const dados = () => {
        rollAll()
        xerarNovoNome()
    }

    return (
        <div className="NameUser">
            <div className="WizardContainer">
                <div className="Descricion">
                    {props.datos.rol === ROL_KID &&
                    <>
                        {t('register.titlenombrekid')}
                        <div className={"subtitle"}>{t('register.textonombrekid')}</div>
                    </>
                    }
                    {props.datos.rol === ROL_TEEN &&
                    <p>{t('register.textonombrepreteen')}</p>
                    }
                </div>
                {props.datos.rol === ROL_KID &&
                <>
                    <div className="NomeRandom">
                        {nomeRandom}
                    </div>
                    <div className="BotonRandom">
                        <div onClick={dados}>
                            <ReactDice
                                numDice={2}
                                dotColor={'#ffffff'}
                                faceColor={'#1FAADE'}
                                margin={2}
                                dieSize={80}
                                rollTime={0.2}
                                disableIndividual={true}
                                ref={dice => reactDice = dice}
                            />
                        </div>
                    </div>
                </>
                }
                {props.datos.rol === ROL_TEEN &&
                <>
                    <label className="campoForm">
                        <input className="InputNome" type="text" value={nome} onChange={handleChangeNome}/>
                    </label>
                </>
                }
                {(invalido || (invalidUsername && props.datos.username !==''))&&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidusername')}/>
                }
            </div>
            <RegisterNavigationComponent handlerBeforePage={props.anteriorPantalla} handlerNextPage={validar}
                                         followButtonDisabled={invalidUsername || invalido}/>
        </div>
    );
}

export default NameUser;
