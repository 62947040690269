import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { getNextScreen } from "@components/Routes/Location";
import "./Splash.css";
import { splash } from "@core";
import { useKeycloak } from "@react-keycloak/web";

const Splash = withRouter(() => {
  const [nextScreen, setNextScreen] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    getNextScreen(splash.controller).then((n) => setNextScreen(n));
    if (!keycloak.authenticated) {
      setTimeout(() => setLoaded(true), 1500);
    }
  }, [keycloak.authenticated]);

  if (!keycloak.authenticated && (!nextScreen || !loaded)) {
    return <></>;
  }
  return <Redirect to={nextScreen} />;
});

export default Splash;
