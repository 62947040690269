import React, {useEffect} from "react";
import {turnOffLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import {useDispatch, useSelector} from "react-redux";
import './CheckoutComponent.css';
import {useTranslation} from 'react-i18next';
import {URL_PLAYER, URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import {useParams, useHistory} from "react-router-dom";
import {User} from "@user/Context/User";
import iconParty from '@app/Resources/Images/row2.svg'
import unlock from '@app/Resources/Images/unlock.png'


const CheckoutComponent = ({success}) => {

    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.commonState.loading);

    const tenantStyles = useSelector(state => state.commonState.tenantStyles);

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator());
    };

    useEffect(() => {
        if (loading) {
            turnOffLoading();
        }

    }, [loading]);

    const handleRedirectToDetail = () => {
        history.push(URL_VIDEODETAIL + '/' + id)
    };

    const handleRedirectToPlayer = () => {
        history.push(URL_PLAYER + '/' + id)
    };


    return (
        <div className={"CheckoutPage"} style={{ "background-image": "url("+ tenantStyles?.backgroundRegister+")" }} >
            {success &&
                <h1 className={"paymentTitle"}><img src={unlock} alt={'unlock'}/>{t('products.checkout.payment')}</h1>
            }

            <div className={"modalCheckout"}>
                <div className={"Logo-checkout"} />
                {success ? <User.Consumer>
                    {User => (User && <>
                                <div ><h2 className={'title'}>{t('products.checkout.congratulations')}</h2></div>
                                <p>{User.userName}</p>
                                <div className={'confirmContent'}>
                                <div className="IconoConfirm">
                                <div className={'IconParty'}>
                                <img src={iconParty} alt={'party'}/>
                                </div>
                                <img src={User.avatar?.url}/>
                                <div className={'IconParty'}>
                                <img className={'rotateImage'} src={iconParty} alt={'party'}/>
                                </div>
                                </div>
                                </div></>)
                        }
                        </User.Consumer>
                    : <>
                        <div ><h2 className={'title'}>{t('products.checkout.fail')}</h2></div>
                        </>
                }
                {success ? <>
                        <h3>{t('products.checkout.paymentConfirm')}</h3>
                        <p>{t('products.checkout.success')}</p>
                    </>
                    : <p>{t('products.checkout.cancel')}</p>
                }
                <div className="ReportOptionButtonContainer">
                    <button onClick={handleRedirectToDetail}
                            className="CheckoutButton button-focused">{t('global.button.go.to.detail')}</button>
                    {success && <button onClick={handleRedirectToPlayer}
                             className="CheckoutButton button-focused">{t('global.button.go.to.player')}</button>}
                </div>
            </div>
        </div>
    )
};

export default CheckoutComponent;