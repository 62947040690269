import React, { useState } from "react";

import { BarraBusqueda } from "./BarraBusqueda";
import { URL_SEARCH } from "../../../../../Components/Routes/Location";

export function BarraBusquedaMobile({ buttonSearch }) {
  const isSearchSection = () => {
    return window.location.href.includes(URL_SEARCH);
  };

  const [displaySearchBar, setDisplaySearchBar] = useState(isSearchSection);
  const [resetValue, setResetValue] = useState(false);

  const handleDisplaySearchBar = (value) => {
    setDisplaySearchBar(value);
    setResetValue(true);
  };

  return (
    <>
      {displaySearchBar && (
        <div className="BarraBusquedaMobil">
          <BarraBusqueda buttonSearch={buttonSearch} resetValue={resetValue} />
        </div>
      )}
      {displaySearchBar ? (
        <div className={"PecharBarraBusqueda"} onClick={() => handleDisplaySearchBar(false)}>
          <ion-icon name="close" />
        </div>
      ) : (
        <div className="IconSearhMobile" onClick={() => handleDisplaySearchBar(true)}>
          <ion-icon name={buttonSearch.section.iconId} />
        </div>
      )}
    </>
  );
}
