import React, {useState} from "react";
import HandleClickOutside from "../../../../../app/src/Components/DOM/Click/HandleClickOutside";
import './PasswordModalComponent.css';
import {useTranslation} from 'react-i18next';
import {InfoPanel} from "../../../../../app/src/Components/GUI/InfoPanel/InfoPanel";
import {handleShowPassword} from "../../../../../app/src/Utils/utils";


const PasswordModalComponent = ({
                                    refPass, handlePasswordModal, handleSendUserCredentials, disabledPassEdit,
                                    currentPassword, newPassword1, newPassword2, handleSetCurrentPassword, handleSetNewPassword1, handleSetNewPassword2, handleCheckNewPassword,
                                    handleSetWrongCurrentPassword
                                }) => {

    const {t} = useTranslation();
    const [showPassword, setShowPassword] = useState([false,false,false]);



    return (
        <div className="PasswordModalBackground">
            <HandleClickOutside elementref={refPass} callback={() => handlePasswordModal(false)}/>
            <div className="PasswordModal" ref={refPass}>
                <p className="PasswordTitle">{t('perfil.password.title')}</p>
                <div className="PasswordContainer">
                    <label className={"PasswordBlock"}>
                        <p className={"PasswordText"}>{t("perfil.password.current")}</p>
                        <input className="PasswordInput" type={showPassword[0] ? "text" : "password"}
                               placeholder={t("perfil.password.current")}
                               value={currentPassword}
                               onChange={handleSetCurrentPassword}
                               onBlur={handleSetWrongCurrentPassword}/>
                        <div className={"iconInput"} onClick={()=> setShowPassword(handleShowPassword(0, showPassword))}>
                            <ion-icon name={showPassword[0] ? "eye-outline" : "eye-off-outline"}/>
                        </div>
                    </label>
                    <InfoPanel classButton={"passwordInfo"} classPanel={"Enumeracion"} icon={"fa fa-info-circle"}
                               hideonclick={true} displayonvoer={true}>
                        <span className={"Linea"}>{t('register.infopassword1')}</span>
                        <span className={"Linea"}>{t('register.infopassword2')}</span>
                        <span className={"Linea"}>{t('register.infopassword3')}</span>
                        <span className={"Sublinea"}>{t('register.infopassword4')}</span>
                    </InfoPanel>
                    <label className={"PasswordBlock"}>
                        <p className={"PasswordText"}>{t("perfil.password.new1")}</p>
                        <input className="PasswordInput" type={showPassword[1] ? "text" : "password"}
                               placeholder={t("perfil.password.new1")}
                               value={newPassword1}
                               onChange={handleSetNewPassword1}
                               onBlur={() => handleCheckNewPassword(newPassword1)}/>
                        <div className={"iconInput"} onClick={()=>setShowPassword(handleShowPassword(1, showPassword))}>
                            <ion-icon name={showPassword[1] ? "eye-outline" : "eye-off-outline"}/>
                        </div>
                    </label>
                    <label className={"PasswordBlock"}>
                        <p className={"PasswordText"}>{t("perfil.password.new2")}</p>
                        <input className="PasswordInput" type={showPassword[2] ? "text" : "password"}
                               placeholder={t("perfil.password.new2")}
                               value={newPassword2}
                               onChange={handleSetNewPassword2}/>
                        <div className={"iconInput"} onClick={()=>setShowPassword(handleShowPassword(2, showPassword))}>
                            <ion-icon name={showPassword[2] ? "eye-outline" : "eye-off-outline"}/>
                        </div>
                    </label>
                </div>
                <div className="ButtonContainer">
                    <button onClick={() => handlePasswordModal(false)}
                            className="ReportOptionButton button-focused PasswordButton">{t('global.button.close')}</button>
                    <button onClick={() => handleSendUserCredentials(currentPassword, newPassword1, newPassword2)}
                            disabled={disabledPassEdit}
                            className={disabledPassEdit ? "DisabledButton PasswordButton" : "button-focused PasswordButton"}>{t('global.button.change.password')}</button>
                </div>
            </div>
        </div>
    )
};

export default PasswordModalComponent;