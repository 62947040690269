import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import MobileStepper from '@material-ui/core/MobileStepper';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {Row, Col, Button} from 'reactstrap';

import {ROL_KID, ROL_TEEN, ROL_ADULT} from './PantallasRexistro';
import {getCodeLang} from '@app/Components/i18n/Language';

import "react-datepicker/dist/react-datepicker.css";
import {InfoPanel} from "@components/GUI/InfoPanel/InfoPanel";
import AlertNotification from "@components/GUI/Alert/Alert";
import Content from "../../../../catalogue/src/Pages/Content/Content";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";
import {SIGNUP_MODE} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {DEFAULT_SIMPLE_BIRTH_DATE, SIGNUP_MODE_SIMPLE} from "./RegisterConstant";
import {invalidCharacter} from "./NameUser";
import {handleShowPassword} from "../../../../app/src/Utils/utils";

let timeoutN = null;
let timeoutA = null;
let timeoutU = null;
let timeoutE = null;

function DataUser(props) {

    const {invalidUsername, invalidData, invalidPassword, invalidEmail} = props;

    const [language, setLanguage] = useState(null);
    const [showPassword, setShowPassword] = useState([false,false]);
    const [nome, setNome] = useState("");
    const [apelido, setApelido] = useState("");
    const [data, setData] = useState(null);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [currentpassword, setCurrentpassword] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [invalidPassword2, setInvalidPassword2] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);
    const [UsernameInvalid, setUsernameInvalid] = useState(props.invalidUsername);
    const refMsgError = useRef(null)
    const [followButtonDisabled, setFollowButtonDisabled] =
        useState(UsernameInvalid || invalidData || invalidPassword || invalidEmail)

    const {t, i18n} = useTranslation();

    const {updateUserData, gotoValidateToUpdateUserData} = props;

    useEffect(() => {
        setFollowButtonDisabled(UsernameInvalid || invalidData || invalidPassword || invalidEmail || invalidUsername);
    }, [invalidUsername, invalidData, invalidPassword, invalidEmail]);

    //Obter cliente por defecto
    useEffect(() => {
        if (props.datos.language === null) {
            let deflang = getCodeLang(i18n.language);
            setLanguage(deflang)
            props.ctrlSetPreferredLanguage(deflang);
        } else{
            setLanguage(props.datos.language)
        }

        //Datos previos

        if (props.datos.rolObject) {
            props.ctrlSetRole(props.datos.rolObject)
        }
        if (props.datos.username) {
            setUsername(props.datos.username)
        }
        if (props.datos.firstName) {
            setNome(props.datos.firstName)
        }
        if (props.datos.lastName) {
            setApelido(props.datos.lastName)
        }
        if (props.datos.email) {
            setEmail(props.datos.email)
        }
        if (props.datos.birthday) {
            let ds = moment(props.datos.birthday, 'YYYY-MM-DD').toDate();
            setData(ds)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Inputs
    const handleChangeNome = (e) => {
        if (timeoutN) {
            clearTimeout(timeoutN)
        }

        var n = e.target.value;
        setNome(n)
        setInvalidFields(false);

        timeoutN = setTimeout(() => {
            props.ctrlSetFirstName(n);
        }, 1500)
    }
    const handleChangeApelido = (e) => {
        if (timeoutA) {
            clearTimeout(timeoutA)
        }

        var n = e.target.value;
        setApelido(n)
        setInvalidFields(false);

        timeoutA = setTimeout(() => {
            props.ctrlSetLastName(n);
        }, 1500)
    }
/*    const handleChangeData = (d, e) => {
        if (d) {
            setData(d)
            let ds = moment(d).format("YYYY-MM-DD")
            setInvalidFields(false);
            props.ctrlSetBirthday(ds);
        }
    }*/
    const handleChangeUsername = (e) => {
        setUsernameInvalid(false);

        if (timeoutU) {
            clearTimeout(timeoutU)
        }
        const withSpace = /\s/;

        const u = e.target.value;
        if(u.toUpperCase().includes(invalidCharacter) || withSpace.test(u) ) {
            setUsernameInvalid(true);
        } else {
            setInvalidFields(false);
        }
        setUsername(u)

        timeoutU = setTimeout(() => {
            props.ctrlSetUsername(u);
        }, 1500)
    }

    const handleChangeEmail = (e) => {
        if (timeoutE) {
            clearTimeout(timeoutE)
        }

        var u = e.target.value;
        setEmail(u)
        setInvalidFields(false);

        timeoutE = setTimeout(() => {
            props.ctrlSetEmail(u);
        }, 1500)
    }

    const handleChangeCurrentPassword = (e) => {
        var u = e.target.value;
        let d = props.datos;
        d.currentPassword = u;
        props.actualizarDatos(d);
        setCurrentpassword(u);
    }

    const handleChangePassword = (e) => {
        var u = e.target.value;
        setPassword(u)
        if (u == password2) {
            setInvalidPassword2(false);
        }
    }

    const handleChangePassword2 = (e) => {
        var u = e.target.value;
        setPassword2(u)
    }

    const handleFinishPassword2 = (e) => {
        setInvalidFields(false);
        setInvalidPassword2(false);
        if (password !== password2) {
            setInvalidPassword2(true);
        } else if (password2 != null && password2 != "") {
            props.ctrlSetCredentials(password2);
        }
    }

    //Validacion
    const validar = () => {
        if (!nome || !apelido || !email || !password || !password2 || password2 == "" || !username) {
            setInvalidFields(true);
            return;
        } else {
            setInvalidFields(false);
        }
        //Contrasinal
        if (password === password2) {
            setInvalidPassword2(false)
            addPasswordToData()
        } else {
            setInvalidPassword2(true)
            return;
        }

        if(props.validar){
            props.validar(password2);
        }

    }

    const addPasswordToData = () => {
        const d = props.datos;
        d.currentPassword = password;
        props.actualizarDatos(d);
    }

    useEffect( () => {
        if(gotoValidateToUpdateUserData) {
            validar();
        };
    }, [gotoValidateToUpdateUserData])

    const dataText = "Merda<br/>tierna";

    return (
        <div className="DataUser">
            <div className="WizardContainer">
                <div className="Descricion">

                    {(props.datos.rol === ROL_KID || props.datos.rol === ROL_TEEN) &&
                    <>
                        <div dangerouslySetInnerHTML={{__html: t('register.titulodatauserkid')}}></div>
                        <div className={"subTitle"} dangerouslySetInnerHTML={{__html: t('register.textodatauserkid')}}></div>
                    </>
                    }
                    {(props.datos.rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) &&
                    <>
                        <div dangerouslySetInnerHTML={{__html: t('register.titulodatauserteen')}}></div>
                    </>

                    }
                </div>
                {(props.datos.rol === ROL_KID || props.datos.rol === ROL_TEEN) &&
                <>

                </>
                }
                    <Row className={"formFirstLine"}>
                        <Col xs="12" md={6}>
                            <label className="campoForm campoFirstName">
                                <input className="InputNome" type="text" placeholder={t('register.datanome')} value={nome}
                                       onChange={handleChangeNome}/>
                            </label>
                        </Col>
                        <Col xs="12" md={6}>
                            <label className="campoForm campoLastName">
                                <input className="InputApelido" type="text" placeholder={t('register.dataapelido')} value={apelido}
                                       onChange={handleChangeApelido}/>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md={6}>
                        </Col>
                        <Col xs="12" md={6}>
                        </Col>
                    </Row>
                    <Row>
                       {/* <Col xs="12" md={6}>
                            <div className="campoForm campoData">
                                <DatePicker id="dataNacemento" dateFormat="dd/MM/yyyy" selected={data}
                                            placeholderText={t('register.datadata')}
                                            onChange={date => handleChangeData(date)}
                                            maxDate={new Date()} showYearDropdown showMonthDropdown yearDropdownItemNumber={100}
                                            scrollableYearDropdown
                                            popperClassName={"popperDatePicker"}
                                />
                            </div>
                        </Col>*/}
                        <Col xs="12" md={6}>
                            <label className="campoForm campoEmail">
                                <input className="InputEmail" type="text" placeholder={t('register.dataemail')} value={email}
                                       onChange={handleChangeEmail}/>
                            </label>
                        </Col>
                    </Row>


                {(props.datos.rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) &&
                <Row>
                    <Col xs="12" md={6}>
                    <label className="campoForm campoUsername">
                    <input className="InputUsuario" type="text" placeholder={t('register.datausername')}
                    value={username} onChange={handleChangeUsername}/>
                    </label>
                    </Col>
                    <Col xs="12" md={6}>
                    <label className="campoForm currentPassword">
                    <h2>{t('register.updatepassword')}</h2>
                    <input className="InputPassword" type={showPassword[0] ? "text" : "password"} placeholder={t('register.currentpassword')}
                    value={currentpassword} onChange={handleChangeCurrentPassword}/>
                        <div className={"iconInput"} onClick={()=>setShowPassword(handleShowPassword(0, showPassword))}>
                            <ion-icon name={showPassword[0] ? "eye-outline" : "eye-off-outline"}/>
                        </div>
                    </label>
                    </Col>
                </Row>

                    }
                    <InfoPanel classButton={"passwordInfo"} classPanel={"Enumeracion"} icon={"fa fa-info-circle"}
                               hideonclick={true} displayonvoer={true}>
                        <span className={"Linea"}>{t('register.infopassword1')}</span>
                        <span className={"Linea"}>{t('register.infopassword2')}</span>
                        <span className={"Linea"}>{t('register.infopassword3')}</span>
                        <span className={"Sublinea"}>{t('register.infopassword4')}</span>
                    </InfoPanel>
                    <Row>
                        <Col xs="12" md="6">
                            <label className="campoForm campoPassword">
                                <input className="InputPassword" type={showPassword[0] ? "text" : "password"}
                                       placeholder={t('register.datapassword')}
                                       value={password} onChange={handleChangePassword}/>
                                <div className={"iconInput"} onClick={()=>setShowPassword(handleShowPassword(0, showPassword))}>
                                    <ion-icon name={showPassword[0] ? "eye-outline" : "eye-off-outline"}/>
                                </div>
                            </label>

                        </Col>
                        <Col xs="12" md="6">
                            <label className="campoForm campoPassword2">
                                <input className="InputPassword" type={showPassword[1] ? "text" : "password"}
                                       placeholder={t('register.datapassword2')} value={password2}
                                       onChange={handleChangePassword2} onBlur={handleFinishPassword2}/>
                                <div className={"iconInput"} onClick={()=>setShowPassword(handleShowPassword(1, showPassword))}>
                                    <ion-icon name={showPassword[1] ? "eye-outline" : "eye-off-outline"}/>
                                </div>
                            </label>
                        </Col>
                    </Row>


                <div ref={refMsgError}>
                    {invalidPassword2 &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidpassword2')}/>}
                    {(UsernameInvalid || invalidUsername) &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidusername')}/>}
                    {props.invalidEmail &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidemail')}/>}
                    {props.invalidData &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invaliddata')}/>}
                    {props.invalidPassword &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidpassword')}/>}
                    {invalidFields &&
                    <AlertNotification type="error" showtitle={false} text={t('register.invalidfields')}/>}
                </div>
            </div>
            {
                !updateUserData &&
                <RegisterNavigationComponent handlerBeforePage={props.anteriorPantalla}
                                             handlerNextPage={validar}
                                             followButtonDisabled={followButtonDisabled || invalidPassword2 || UsernameInvalid} />
            }

        </div>
    );
}

export default DataUser;
