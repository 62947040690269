export const SELECTAGE = "SeleccionIdade";
export const NAMEUSER = "NomeUsuario";
export const AVATAR = "Avatar";
export const CTITOR = "ChamarTitor";
export const CTITOR2 = "ChamarTitor2";
export const DATAUSER = "DatosUsuario";
export const CATEGORY = "Categoria";
export const CONDITIONS = "CondicionsUso";
export const CONFIRMATION = "Confirmacion";
export const FINISHED = "Finished";

export const ROL_KID = "Kid";
export const ROL_TEEN = "Teen";
export const ROL_ADULT = "Adult";


export const ROL = {
    [ROL_KID] :{
        steps:7
    },
    [ROL_TEEN] :{
        steps:7
    },
    [ROL_ADULT] :{
        steps:5
    },
}

export const getRoleObject = (roles, rolStr) => {
    var result = null;
    if (roles) {
        roles.forEach((r) => {
            if(r.constructor.$metadata$.simpleName === rolStr){
                result = r;
            }
        })
    }
    return result;
}

export const getRoleStr = (rolObj) => {
    var result = null;
    if (rolObj) {
        result = rolObj.constructor.$metadata$.simpleName;
    }
    return result;
}