import React, {useState} from "react";
import {Row} from 'reactstrap';

import UserInfoComponent from "./components/UserInfoComponent/UserInfoComponent";
import AchievementComponent from "./components/AchievementsComponent";
import RankingListComponent from "./components/RankingListComponent";
import MobileMenuComponent from "./components/MobileMenuComponent";

const RankingMobileComponent = ({rankingData, renderMobileVersion, toggleCertificateModal, userName}) => {

    const [renderList, setRenderList] = useState(true);

    const handleSetRenderList = (value) => {
        setRenderList(value)
    };

    return (
        <>
            <Row xs="12">
                <div className="Datos-usuario">
                    <UserInfoComponent userAvatar={rankingData.userAvatar} userRanking={rankingData.userRanking}
                                       closestAchievement={rankingData.closestAchievement} userName={userName}/>
                </div>
                <MobileMenuComponent handleSetRenderList={handleSetRenderList} renderList={renderList}/>
                {renderList ?
                    <RankingListComponent rankings={rankingData.rankings} userAvatar={rankingData.userAvatar} userRanking={rankingData.userRanking}
                                          renderMobileVersion={renderMobileVersion}/> :
                    <AchievementComponent achievements={rankingData.achievements} progress={rankingData.progress}
                                          renderMobileVersion={renderMobileVersion} rewards={rankingData.rewards} toggleCertificateModal={toggleCertificateModal}/>}
            </Row>
        </>
    )
};

export default RankingMobileComponent;