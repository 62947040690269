import React from 'react';
import {useTranslation} from 'react-i18next';
import Carousel from "react-multi-carousel";

import './Casting.css';
import participanteDefault from '@app/Resources/Images/participante_default.png';


function Casting(props) {
    const {t} = useTranslation();

    const responsive = {
        xxldesktop: {
            breakpoint: {max: 3000, min: 1960},
            items: 6,
            slidesToSlide: 6
        },
        xldesktop: {
            breakpoint: {max: 1960, min: 1480},
            items: 5,
            slidesToSlide: 5
        },
        desktop: {
            breakpoint: {max: 1480, min: 1130},
            items: 4,
            slidesToSlide: 4
        },
        tablet: {
            breakpoint: {max: 1130, min: 800},
            items: 3,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: {max: 800, min: 0},
            items: 2,
            slidesToSlide: 2
        }
    };

    return (
        <div className={"PlaylistContainer Playlist " + props.className}>
            {props.participants?.length > 0 &&
            <>
                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={false}
                    autoPlay={false}
                    keyBoardControl={false}
                    transitionDuration={1000}
                    customTransition="transform 1000ms ease-in-out 0s"
                    containerClass="carousel-container PlaylistCollection PersonaxeList"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    centerMode={true}>
                    {props.participants.map((r) =>
                        <div className="PersonaxeItem" key={r.name + " " + r.surname}>
                            {r.image?.url &&
                            <img className="PersonaxeImaxe" src={r.image?.url}/>
                            }
                            {r.image?.url === null &&
                            <img className="PlaylistItemImage" src={participanteDefault} alt={w.title}/>
                            }
                            <div className="PersonaxeNome">{r.name + " " + r.surname}</div>
                        </div>
                    )
                    }
                </Carousel>
            </>
            }
        </div>
    );
}

export default Casting;
