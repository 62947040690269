import * as React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import type { RouteProps } from "react-router-dom";

import Menu from "@app/Pages/Menu/Menu";
import { URL_SPLASH } from "./Location";
import { useKeycloak } from "@react-keycloak/web";
import Footer from "@components/GUI/Footer/Footer";

interface WithMenuRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export function WithMenuRoute({ component: Component, ...rest }: WithMenuRouteParams) {
  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <>
            <Menu {...props} {...rest} />
            <div className={"WithMenuContainer" + (rest?.nofooter ? " Nofooter" : "")}>
              <Component {...props} {...rest} />
            </div>
            {!rest?.nofooter && <Footer />}
          </>
        )}
      />
    </>
  );
}

export function WithMenuRoutePrivate({ component: Component, ...rest }: WithMenuRouteParams) {
  const { keycloak } = useKeycloak();

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          keycloak?.authenticated ? (
            <>
              <Menu {...props} {...rest} />
              <div className={"WithMenuContainer" + (rest?.nofooter ? " Nofooter" : "")}>
                <Component {...props} {...rest} />
              </div>
              {!rest?.nofooter && <Footer />}
            </>
          ) : (
            <Redirect
              to={{
                pathname: URL_SPLASH,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
}
