import React, {useEffect, useState} from "react";
import CarouselAuto from 'react-bootstrap/Carousel'
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import Spinner from 'react-bootstrap/Spinner';
import {useSelector} from "react-redux";

import HighlightSlideComponent from "./HighlightSlideComponent";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import {SWITCHER_WIDTH} from "../../../../../app/src/Utils/Constants";


const HighlightWidgetComponent = ({playlist, rotateTime, setRef, getRef, defaultBanner,
                                      access, clickCallback, turnOffLoading}) => {

    const [playSpinner, setPlaySpinner] = useState(true);
    const loading = useSelector(state => state.commonState.loading);

    useEffect(() => {
        !!loading && turnOffLoading()
        playlist?.length > 0 && setPlaySpinner(false)
    }, [playlist]);

    const showIndicators = () => {
        if (window.innerWidth <= SWITCHER_WIDTH) {
            return MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators;
        } else {
            return true;
        }
    }


    return (<>
        <div className="CarouselContainer Principal">
            {playlist?.length > 0 &&
            <CarouselAuto
                controls={false}
                interval={60000}
                indicators={showIndicators()}>
                {playlist?.map((w) =>
                        <CarouselAuto.Item
                            interval={rotateTime} key={w.id}>
                            <HighlightSlideComponent w={w} setRef={setRef} getRef={getRef} defaultBanner={defaultBanner}
                                                     access={access} clickCallback={clickCallback}/>
                        </CarouselAuto.Item>
                )}
            </CarouselAuto>
            }
            {
                playSpinner && <div className="SpinnerContainer">
                    <Spinner animation="border" variant="primary" />
                </div>
            }

        </div>

    </>
    )
};

export default HighlightWidgetComponent;