import React from 'react';

function VideoTag(props) {
    return (
        <div className="VideoTag">
            <div className="Subtag PlaylistItemSubLabelTag">
                {props.tag}
            </div>
        </div>
    );
}

export default VideoTag;