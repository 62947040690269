import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { checkMenuActive } from "../../../../../Utils/utils";
import { useSelector } from "react-redux";

const MenuSectionsComponent = ({ sections, changeSection, changeDynamicSection, getIconSection }) => {
  const sectionSelected = useSelector((state) => state.commonState.sectionSelected);

  return (
    <div>
      {!!sections &&
        sections.map((a, index) =>
          a?.section.screenId ? (
            <OverlayTrigger
              key={index}
              delay={{ hide: 450, show: 300 }}
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>{a?.section.name}</Tooltip>}
            >
              <NavLink
                key={a?.section.iconId}
                to={{
                  pathname: a.url,
                  aboutProps: {
                    dinamic: a.section.screenId,
                  },
                }}
                onClick={() => changeDynamicSection(a.section)}
                className={"MenuSection " + a.section.iconId}
                isActive={(match, location) => checkMenuActive(match, location, a, sectionSelected, sections)}
              >
                {getIconSection(a.section.iconId)}
              </NavLink>
            </OverlayTrigger>
          ) : a.section.type.name$ === "UNKNOWN" ? (
            <></>
          ) : (
            <OverlayTrigger
              key={index}
              delay={{ hide: 450, show: 300 }}
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>{a?.section.name}</Tooltip>}
            >
              <NavLink
                to={{
                  pathname: a.url,
                }}
                onClick={() => changeSection(a.section)}
                className={"MenuSection " + a.section.iconId}
                key={a.section.iconId}
              >
                {getIconSection(a.section.iconId)}
              </NavLink>
            </OverlayTrigger>
          ),
        )}
    </div>
  );
};

export default MenuSectionsComponent;
