import React from 'react';

import useDynamicRefs from 'use-dynamic-refs';
import useWindowDimensions from '@components/DOM/Screen/ScreenDimensions';
import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';

import {
    DATA_LOADING_STATUS_LOADED,
    WIDGET_TYPE_CIRCULAR,
    WIDGET_TYPE_GROUP_WIDGET,
    WIDGET_TYPE_HIGHLIGHT,
    WIDGET_TYPE_LIVE, WIDGET_TYPE_LIVE_2, WIDGET_TYPE_LIVE_VERTICAL,
    WIDGET_TYPE_NEWS
} from "../../../../app/src/Utils/Constants";

import LiveWidgetComponent from "./Widgets/LiveWidgetComponent";
import HighlightWidgetComponent from "./Widgets/HighlightWidgetComponent";
import GroupWidgetComponent from "./Widgets/GroupWidgetComponent";
import DefaultWidgetComponent from "./Widgets/DefaultWidgetComponent";
import CircularWidgetComponent from "./Widgets/CircularWidgetComponent";

import "react-multi-carousel/lib/styles.css";
import '@detail/Pages/DetailPage/CarrouselStyle.css';
import TextWidgetComponent from "./Widgets/TextWidgetComponent";
import LiveVerticalWidgetComponent from "./Widgets/LiveVerticalWidgetComponent";
import Live_2_WidgetComponent from "./Widgets/Live_2_WidgetComponent";

function Playlist(props) {
    const {isSmall} = useWindowDimensions();
    const [getRef, setRef] = useDynamicRefs();
    const  {turnOffLoading, turnOnLoading, access, titleColor, contentId=null, pageLocation=null,
        statusLoader=DATA_LOADING_STATUS_LOADED} = props;

    const renderWidget = () => {

        switch (props?.typeBanner?.toUpperCase()) {
            case WIDGET_TYPE_HIGHLIGHT:
                return <HighlightWidgetComponent playlist={props.playlist} rotateTime={props.rotateTime} setRef={setRef}
                                                 getRef={getRef} turnOffLoading={turnOffLoading}
                                                 access={props.access} clickCallback={props.clickCallback}
                                                 />
            case WIDGET_TYPE_LIVE_VERTICAL:
                return <LiveVerticalWidgetComponent key={props.key} className={props.className} style={props.style}
                                            titulo={props.titulo}
                                            isSmall={isSmall} turnOffLoading={turnOffLoading}
                                            typeBanner={props.typeBanner} playlist={props.playlist}
                                            access={props.access}
                                            clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                            titleColor={titleColor}/>;
            case WIDGET_TYPE_LIVE:
                return <LiveWidgetComponent key={props.key} className={props.className} style={props.style}
                                            titulo={props.titulo}
                                            isSmall={isSmall} turnOffLoading={turnOffLoading}
                                            typeBanner={props.typeBanner} playlist={props.playlist}
                                            access={props.access}
                                            clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                            titleColor={titleColor}/>;
            case WIDGET_TYPE_LIVE_2:
                return <Live_2_WidgetComponent key={props.key} className={props.className} style={props.style}
                                            titulo={props.titulo}
                                            isSmall={isSmall} turnOffLoading={turnOffLoading}
                                            typeBanner={props.typeBanner} playlist={props.playlist}
                                            access={props.access}
                                            clickCallback={props.clickCallback} hideSubtitle={props.hideSubtitle}
                                            titleColor={titleColor}/>;
            case WIDGET_TYPE_GROUP_WIDGET:
                return <GroupWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                                             style={props.style} access={access}
                                             titulo={props.titulo} turnOffLoading={turnOffLoading}
                                             turnOnLoading={turnOnLoading}
                                             hasMoreContents={props.hasMoreContents} id={props.id}
                                             typeBanner={props.typeBanner}
                                             playlist={props.playlist} titleColor={titleColor} contentId={contentId}/>;
            case WIDGET_TYPE_CIRCULAR:
                return <CircularWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                                                style={props.style} access={access}
                                                titulo={props.titulo} turnOffLoading={turnOffLoading}
                                                turnOnLoading={turnOnLoading}
                                                hasMoreContents={props.hasMoreContents} id={props.id}
                                                typeBanner={props.typeBanner}
                                                playlist={props.playlist} clickCallback={props.clickCallback}
                                                controller={props.controller} event={props.event} titleColor={titleColor}/>;
            case WIDGET_TYPE_NEWS:
                return <TextWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                                            style={props.style}
                                            titulo={props.titulo} turnOffLoading={turnOffLoading}
                                            hasMoreContents={props.hasMoreContents} id={props.id}
                                            typeBanner={props.typeBanner}
                                            playlist={props.playlist} clickCallback={props.clickCallback}
                                            controller={props.controller} event={props.event} titleColor={titleColor}/>;
            default:
                return <DefaultWidgetComponent isSmall={isSmall} key={props.key} className={props.className}
                                               style={props.style} access={access}
                                               titulo={props.titulo} turnOffLoading={turnOffLoading}
                                               hasMoreContents={props.hasMoreContents} id={props.id}
                                               typeBanner={props.typeBanner} turnOnLoading={turnOnLoading}
                                               playlist={props.playlist} clickCallback={props.clickCallback}
                                               controller={props.controller} event={props.event} detail={props.detail}
                                               titleColor={titleColor} contentId={contentId} pageLocation={pageLocation}
                                               statusLoader={statusLoader}
                />;
        }
    }

    const widget = props?.typeBanner && renderWidget();

    return (
        <>
            {widget}
        </>
    );
}

export default Playlist;
