export const TURN_OFF_LOADING_ACTION = "TURN_OFF_LOADING_ACTION";
export const TURN_ON_LOADING_ACTION = "TURN_ON_LOADING_ACTION";
export const DISPLAY_SESSION_EXPIRED_MODAL = "DISPLAY_SESSION_EXPIRED_MODAL";
export const HIDE_SESSION_EXPIRED_MODAL = "HIDE_SESSION_EXPIRED_MODAL";
export const LOAD_TENANT_STYLES = "LOAD_TENANT_STYLES";
export const STORE_PRODUCT_URL = "STORE_PRODUCT_URL";
export const TOGGLE_PASSWORD_MODE = "TOGGLE_PASSWORD_MODE";
export const DISPLAY_ON_BOARDING = "DISPLAY_ON_BOARDING";
export const SELECT_MENU_SECTION = "SELECT_MENU_SECTION";
export const STORE_HOME_SECTION = "STORE_HOME_SECTION";
