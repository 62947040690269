/**
 * Convertir Kotlin iterable en Array JS
 */
export const toArray = (ar) => {
  var ws = [];
  if (ar) {
    var it = ar.iterator();
    while (it.hasNext()) {
      ws.push(it.next());
    }
  }
  return ws;
};
