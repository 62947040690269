import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import appStore from "@app/Resources/Images/App_Store.svg";
import googlePlay from "@app/Resources/Images/google-play.png";
import { useTranslation } from "react-i18next";
import { DEVICE_ANDROID, DEVICE_IOS } from "../../Utils/Constants";

const ModalComponent = ({ className, isOpen, toggleOpenModal, content, title, subContent, appUrlData, device }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => toggleOpenModal(!isOpen)} className={className}>
        <ModalHeader toggle={() => toggleOpenModal(!isOpen)}>{t(title)}</ModalHeader>
        <ModalBody>
          <div className={"contentModalApps"}>{t(content)}</div>
          <div className={"contentModalApps"}>{t(subContent)}</div>
          {device === DEVICE_IOS && appUrlData.iosAppUrl && (
            <div>
              <a href={appUrlData.iosAppUrl} target={"_blank"}>
                <img src={appStore} alt={"App store"} />
              </a>
            </div>
          )}
          {device === DEVICE_ANDROID && appUrlData.androidAppUrl && (
            <div>
              <a href={appUrlData.androidAppUrl} target={"_blank"}>
                <img src={googlePlay} alt={"Google Play"} />
              </a>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalComponent;
