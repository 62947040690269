import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../Menu.css";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../Resources/Multitenant/tenantConstants";

const UserSectionsComponent = ({
  studioSection,
  configurationSection,
  logOutSection,
  logout,
  changeSection,
  configurationIcon,
  logoutIcon,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {!!studioSection && (
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          placement="bottom"
          overlay={(props) => <Tooltip {...props}>{studioSection.section.name}</Tooltip>}
        >
          {studioSection.url && (
            <a href={studioSection.url} className={"MenuSection " + studioSection.section.iconId}>
              <ion-icon name={studioSection.section.iconId} />
            </a>
          )}
        </OverlayTrigger>
      )}
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        placement="bottom"
        overlay={(props) => <Tooltip {...props}>{t(configurationSection.section.name)}</Tooltip>}
      >
        <NavLink
          to={{ pathname: configurationSection.url }}
          onClick={() => changeSection(configurationSection.section)}
          className={"MenuSection " + configurationSection.section.iconId}
          key={configurationSection.section.iconId}
        >
          {configurationIcon}
        </NavLink>
      </OverlayTrigger>
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        placement="right"
        overlay={(props) => <Tooltip {...props}>{logOutSection.section.name}</Tooltip>}
      >
        <a
          onClick={logout}
          className={
            "MenuSection " + logOutSection.section.iconId + " " + MULTITENANT_CONSTANTS[TENANT].menuClassLogout
          }
          target={"blank"}
        >
          {logoutIcon}
        </a>
      </OverlayTrigger>
    </div>
  );
};

export default UserSectionsComponent;
