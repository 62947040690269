import React from "react";
import iconTrophy from '@app/Resources/Images/icon_trophy.svg';
import '../../../../Ranking.css';
import defaultUserAvatar from '@app/Resources/Images/default-user-avatar.png';
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../../../../app/src/Resources/Multitenant/tenantConstants";


const AvatarComponent = ({userAvatar, userRanking, userName}) => {
    return (
        <>
            <div className={"Avatar-imaxe-container"}>
                <img
                    className={"Avatar-imaxe"}
                    src={userAvatar?.url || defaultUserAvatar}
                    alt={userName}
                />
            </div>
            {
                MULTITENANT_CONSTANTS[TENANT].gamification &&
                <div className="Avatar-points">
                    <img
                        className={"Icono-trophy"}
                        src={iconTrophy}
                        alt={"Points"}
                    />
                    <span className={"Avatar-points-text"}>{userRanking?.points}</span>
                </div>
            }

        </>
    )
};

export default AvatarComponent;