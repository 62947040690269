import React from "react";
import HandleClickOutside from "../../../../../app/src/Components/DOM/Click/HandleClickOutside";
import './AvatarModalComponent.css';
import {useTranslation} from 'react-i18next';
import {Col, Row} from "react-bootstrap";


const AvatarModalComponent = ({refAvatar, handleAvatarModal, handleSendAvatar, availableAvatars, avatar, handleSetAvatar}) => {

    const {t} = useTranslation();

    return (
        <div className="AvatarModalBackground">
            <HandleClickOutside elementref={refAvatar} callback={handleAvatarModal}/>
            <div className="AvatarModal" ref={refAvatar}>
                <p className="AvatarTitle">{t('perfil.avatar.title')}</p>
                <div className="AvatarsContainer">
                    <Row>
                        {availableAvatars &&
                        availableAvatars.map((a, index) =>
                            <Col xs="4" md="3" key={a.name + '_' + index}>
                                <div className="AvatarCont">
                                    <img
                                        className={"RegisterAvatarImaxe " + (avatar && avatar.id === a.id ? 'Selected' : '')}
                                        key={a.id} onClick={() => handleSetAvatar(a)} src={a.url} alt={a.id}/>
                                </div>
                            </Col>)
                        }
                    </Row>
                </div>
                <div className="ButtonContainer">
                    <button onClick={handleAvatarModal}
                            className="ReportOptionButton button-focused CancelButton">{t('global.button.cancel')}</button>
                    <button onClick={() => handleSendAvatar(avatar)}
                            className="button-focused CancelButton">{t('global.button.accept')}</button>
                </div>
            </div>
        </div>
    )
};

export default AvatarModalComponent;