import React from "react";
import { useTranslation } from "react-i18next";
import "./ErrorMessageModalComponent.css";
import { useHistory } from "react-router-dom";
import { URL_HOME } from "../Routes/Location";

const ErrorMessageModalComponent = ({
  textTitle = "error.modal.common.title",
  textBody = "error.modal.rating.message",
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCloseModal = () => {
    history.push(URL_HOME);
  };

  return (
    <div className="ModalBackground">
      <div className="ErrorMessageModal">
        <strong className={"ErrorModalTitle"}>{t(textTitle)}</strong>
        <div className={"ModalContent"}>
          <p>{t(textBody)}</p>
        </div>
        <div className="ButtonContainer">
          <button onClick={() => handleCloseModal()} className="CancelButton button-focused">
            {t("global.button.go.back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageModalComponent;
