import React from "react";

import spinner from "./Resources/spinner.png";

const PetisgoLoader = () => {
  return (
    <div>
      <img src={spinner} />
    </div>
  );
};

export default PetisgoLoader;
