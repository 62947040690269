import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {useDispatch} from "react-redux";
import {URL_LOGIN} from '@components/Routes/Location';
import AlertNotification from "@components/GUI/Alert/Alert";
import Iframe from 'react-iframe';
import './Player.css';
import '@videojs/themes/dist/city/index.css';
import '@videojs/themes/dist/fantasy/index.css';
import '@videojs/themes/dist/forest/index.css';
import '@videojs/themes/dist/sea/index.css';
import {playback, interops} from '@core'
import {User} from "@user/Context/User";
import {compareUserProfileObjects} from "../../../../app/src/Utils/utils";
import QRModalComponent from "../../../../app/src/Components/common/QRModalComponent";
import {
    storeProductURLActionCreator,
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../app/src/actions/commonActions";
import {
    PLAYBACK_LOCK_REASON_NOT_PURCHASED,
    PLAYBACK_LOCK_REASON_RATING_LOCKED,
    PLAYER_TYPE_AR
} from "./PlayerConstants";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {DATA_LOADING_STATUS_LOADED} from "../../../../app/src/Utils/Constants";
import {URL_PRODUCTS} from "../../../../app/src/Components/Routes/Location";
import {useHistory} from "react-router-dom";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";



function Player({id}) {

    const dispatch = useDispatch();
    const outputFn = useOutput();
    const history = useHistory();
    const [player, setPlayer] = useState(null);
    const [error, setError] = useState(false);
    const [errorUserSessionExpired, setErrorUserSessionExpired] = useState(false);
    const [errorVideoUrlUnavailable, setErrorVideoUrlUnavailable] = useState(false);
    const [displayQRModal, setDisplayQRModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const {userName} = useContext(User);

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };


    const storeProductURL = data => {
        dispatch(storeProductURLActionCreator(data))
    };



        useEffect(() => {
        turnOnLoading()

        const con = new playback.controller;
        let binding = con.bind(model, o => outputFn(o, output, playback));
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ignoreList = urlParams.get('ignore')
        const ev = new playback.events.LoadVideoUrl(id, ignoreList);
        con.dispatch(ev)

        return () => {
            con.unbind(binding)
        };
    }, [id]);

    const model = (o) => {
        const formattedModel = {};

        formattedModel.loading = o.loading.name$;
        formattedModel.videoUrl = o.videoUrl;
        formattedModel.type = o.contentType?.name$;



        if (o.shareUrl) {
            formattedModel.shareUrl = o.shareUrl
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(player, formattedModel)) {
            if (player === null) {
                turnOffLoading()
            }
            setPlayer(formattedModel)
        }
    }

    const output = (o) => {
        if (interops.getKotlinName(o.reason) == "UserSessionRequired") {
            handleRedirectToLogin()
        }

        if (interops.getKotlinName(o.reason) === PLAYBACK_LOCK_REASON_NOT_PURCHASED) {
            turnOffLoading();
            !!userName ? handleRedirectToProducts() : handleRedirectToLogin();
        }

        if (interops.getKotlinName(o.reason) === PLAYBACK_LOCK_REASON_RATING_LOCKED) {
            turnOffLoading();
            setMessageModal(true);
        }

        if (interops.getKotlinName(o) === "UserSessionExpired") {
            setErrorUserSessionExpired(true);
        }

        if (o.error) {
            setError(true)
        }
    }

    const handleRedirectToProducts = () => {
        storeProductURL(window.location.href);
        history.push(URL_PRODUCTS + '/' + id);
    }

    const handleRedirectToLogin = () => {
        history.push(URL_LOGIN);
    }


    const handleCloseQRModal = () => {
        setDisplayQRModal(displayQRModal => !displayQRModal)
    };

    useEffect(() => {
        if (player?.type === PLAYER_TYPE_AR && !displayQRModal) {
            setDisplayQRModal(true)
        }
    }, [player])

    return (
        <>
            {<div className={"Player"}>
                {player?.videoUrl &&
                <Iframe url={player.videoUrl}
                        className={"PlayerIframe"}
                        width="100%"
                        height="100%"
                        allow='autoplay; encrypted-media; fullscreen'
                        id='player'
                        display="initial"
                        position="relative"
                />}
            </div>}
            {messageModal && <ErrorMessageModalComponent/>}
            {displayQRModal && player?.shareUrl &&
            <QRModalComponent url={player.shareUrl} goBack={true} handleCloseQRModal={handleCloseQRModal}/>}
            {(errorUserSessionExpired) && <Redirect to={URL_LOGIN}/>}
            {error || errorVideoUrlUnavailable && <AlertNotification/>}
        </>
    )
}

export default Player;
