import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ReactStars from "react-rating-stars-component";

import './Rating.css';
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";

/**
 * Shows a rating with stars
 *
 * @param props.controller controller used to store rating
 * @param props.events events to store rating
 * @param props.userRating rating setted by user (Optional)
 * @param props.totalRating total rating by all users (Optional)
 * @param props.votes number of votes (Optional)
 * @param props.displayTitle if true displays title (Optional)
 * @param props.displayTotal if true displays total rating (Optional)
 * @param props.displayNumVotes if true displays number of votes (Optional)
 *
 * @returns {*}
 * @constructor
 */
function Rating(props) {
    const [initialRating, setInitialRating] = useState(null);
    const [userRating, setUserRating] = useState(null);
    const [totalRating, setTotalRating] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (!!props.userRating) {
            setUserRating(props.userRating)
            if (!initialRating) {
                setInitialRating(props.userRating)
            }
        } else {
            if (props.totalRating >= 0) {
                setInitialRating(props.totalRating)
                } else setInitialRating(null);
            setUserRating(null)
        }

        setTotalRating(props.totalRating)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userRating, props.totalRating, props.id]);


    const ratingChanged = (rating) => {
        setUserRating(rating)
        setInitialRating(null)
        let ev = new props.events.Rate(rating)
        props.controller?.dispatch(ev)
    }

    const ratingProps = {
        size: 30,
        count: 5,
        color:"#ffffff50",
        activeColor:"#FFFFFF",
        emptyIcon: <ion-icon name="star-outline"/>,
        halfIcon: <ion-icon name="star-half-outline"/>,
        filledIcon: <ion-icon name="star"/>,
        edit: props.editRating,
        isHalf: false,
        onChange: ratingChanged
    };

    const ratingInitialProps = {
        size: 30,
        count: 5,
        color:"#ffffff50",
        activeColor:"#FFFFFF",
        edit: props.editRating,
        isHalf: false,
        emptyIcon: <ion-icon name="star-outline"/>,
        halfIcon: <ion-icon name="star-half-outline"/>,
        filledIcon: <ion-icon name="star" style={{opacity: 0.5}}/>,
        onChange: ratingChanged
    };

    return (
        <div className="Rating">
            {props.displayTitle &&
            <div className="RatingTitulo">
                {t('ficha.valoracion')}
            </div>
            }

            {initialRating !== null &&
                    <div className="Estrelas">
                    <ReactStars
                        key={'datail' + initialRating}
                        isHalf={false}
                        value={initialRating}
                    {...ratingInitialProps}
                    />
                        <div className={initialRating !== 0 ? "ratingValue" : "noRatingValue"}>
                            {initialRating !== 0 ? initialRating : t('playlist.rating')}
                        </div>
                    </div>
            }

            {userRating !== null &&
                <div className="Estrelas CurrentScore">
                <ReactStars
                    key={'score' + props.id}
                    isHalf={false}
                    value={userRating}
                    {...ratingProps}
                />
                    <div className={userRating !== 0 ? "ratingValue" : "noRatingValue"}>
                        {userRating !== 0 ? userRating : t('playlist.rating')}
                    </div>
                </div>
                }

            {(props.displayTotal || props.displayNumVotes) &&
            <div className="PuntuacionObtida">
                {props.displayTotal &&
                <>
                    <span className="PuntuacionTotal">
                    {totalRating}
                    </span>
                    <span>
                    {t('ficha.stars')}
                    </span>
                </>
                }
                {props.displayNumVotes &&
                <span className="Votos">
                    {t('ficha.votes', {votes: props.votes})}
                </span>
                }
            </div>
            }
        </div>
    );
}

export default Rating;