import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const SelectLanguagesComponent = ({controller, language, languages, ctrlSetPreferredLanguage}) => {

    const {t, i18n} = useTranslation();

    const languagesName = {
        es: t('lang.es'),
        gl: t('lang.gl'),
        en: t('lang.en'),
        ca: t('lang.ca'),
        vc: t('lang.vc'),
        pt: t('lang.pt'),
    }

    const handleChangeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        ctrlSetPreferredLanguage(event.target.value)
    }

    useEffect(() => {
        if (controller && language) {
            i18n.changeLanguage(language)
            ctrlSetPreferredLanguage(language)
        }
    }, [controller, language])

    return <>
        <div className="LanguageContainer">
            <select onChange={(event) => handleChangeLanguage(event)}>
                {languages && languages.map((a) =>
                    <option value={a} selected={a === language && 'selected'}>
                        {languagesName[a]}
                    </option>)}
            </select>
        </div>
    </>
}

export default SelectLanguagesComponent;