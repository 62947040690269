import React, {useEffect, useContext} from 'react';
import {Redirect} from "react-router-dom";
import {useKeycloak} from '@react-keycloak/web';
import {URL_HOME} from '@components/Routes/Location';
import './Login.css';
import {splash, interops} from '@core'
import {User} from "@user/Context/User";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {ACTION_GAME} from "../../../../home/src/Pages/Home/HomeComponentConstants";
import {setAchievementsAndRewardsInLocalStorage} from "../../../../app/src/Utils/utils";

export default function Login() {
    const {keycloak, initialized} = useKeycloak();
    const {reloadUser} = useContext(User);

    useEffect(() => {
        let con = new splash.controller
        let binding = con.bind(model, output)
        con.dispatch(splash.events.LogLoginView)
        return () => {
            // TODO: To be removed, temporal fix to ensure login action is sent
            //con.unbind(binding);
        }
    }, []);

    const model = (model) => { }

    const output = (output) => {
        if (interops.getKotlinName(output) === 'UnexpectedError') {
            const errorCode = output.code
        }
        if (interops.getKotlinName(output) === 'RequestTimedOut') {

        }
        //        if (o.constructor.$metadata$.simpleName === ACTION_GAME) {
        //            setAchievementsAndRewardsInLocalStorage(o);
        //            if (reloadUser) {
        //                reloadUser()
        //            }
        //        }
        if (interops.getKotlinName(output) === ACTION_GAME) {
            setAchievementsAndRewardsInLocalStorage(output);
            if (reloadUser) {
                reloadUser()
            }
        }
    }


    if (!initialized || !keycloak.clientId) {
        return null;
    }

    if (keycloak) {
        if (!keycloak.authenticated) {
            keycloak.login({locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale});
            return null;
        }
    } else {
        console.log("Non hai keycloak asignado")
    }

    return (
        <Redirect to={URL_HOME}/>
    );
};