import { useDispatch } from "react-redux";
import { displaySessionExpiredModalActionCreator } from "../../actions/commonActions";

const useOutput = () => {
  const dispatch = useDispatch();

  const outputFn = (o, callback, core) => {
    if (o instanceof core.outputs.UserSessionExpired.constructor) {
      dispatch(displaySessionExpiredModalActionCreator());
    }
    callback(o);
  };

  return outputFn;
};

export default useOutput;
