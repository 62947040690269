import React from 'react';
import {evaluateItemClass} from "../utils";
import {actionTypeContainer} from "../../../../../../app/src/Utils/utils";
import {BANNER_HEIGHT, BANNER_WIDTH} from "../../PlaylistConstants";
import {TVG} from "../../../../../../app/src/Utils/Constants";
import VideoType from "../../VideoType";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import iconTVG from '@app/Resources/Images/icono_TVG.png';

const CommonLiveCardComponents = (props) => {

    const {overOnCard, i, w, playlist, setOverOnCard, access, clickCallback, defaultBanner} = props;

    const {t} = useTranslation();

    return (
        <div className={evaluateItemClass({i, overOnCard,
            playlist,itemClass:'PlaylistItemContainer'})} key={w.id + w.video?.url}
             onMouseEnter={()=> setOverOnCard(i)}
             onMouseLeave={()=> setOverOnCard(false)}
        >

            <Link to={{
                pathname: actionTypeContainer(access).ROUTE + "/" + w.id,
                state: {
                    url: w.video?.url,
                    type: w.video?.type,
                    id: w.id
                }
            }}>
                <div
                    className="PlaylistItem Live"
                    onClick={clickCallback ? () => clickCallback(w.id) : ''}
                    key={w.titulo + "-" + w.video?.url}>

                    {(w.background?.url || w.banner?.url) ?
                        <img className="PlaylistItemImage"
                             src={!!w.banner
                                 ? w.banner?.url:
                                 w.background?.url}
                             alt={w.title}/>
                        : <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                    }

                    {w.tags.size !== 0 && w.tags.toArray().includes(TVG) &&

                    <img className="iconTVG" src={iconTVG} alt={"iconTVG"}/>
                    }

                    {(w.background?.url === null && w.banner?.url === null) &&
                    <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                    }

                    <div className="PlaylistItemTitle">{w.title}</div>
                    <div className="PlaylistItemSubtitle">
                        {t('ficha.timeStart')} {new Date(w.startTimeMs?.toNumber()).toLocaleString()}
                    </div>

                    <div className="ButtonAccess"
                         onClick={clickCallback ? () => clickCallback(w.id) : ''}>
                        <div type="button" className="Access button-focused">
                            {t('home.access')}
                        </div>
                    </div>

                </div>
            </Link>
            <VideoType subtitle={w}/>
        </div>
    )
}

export default CommonLiveCardComponents;