import React from "react";

import Spinner from "react-bootstrap/Spinner";

const AndromedaLoader = () => {
  return (
    <div>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default AndromedaLoader;
