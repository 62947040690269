import React from 'react';
import {useTranslation} from 'react-i18next';
import iconParty from '@app/Resources/Images/row2.svg'
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";
import {TENANT, TENANT_TIIVII} from "../../../../app/src/Resources/Multitenant/tenantConstants";

function Confirm(props) {
    const {t} = useTranslation();

    const textByTenant = (typeTitle) => {
        return TENANT === TENANT_TIIVII ? typeTitle + "_TIIVII" : typeTitle;
    }
    return (
        <div className="Confirm">
            <div className="WizardContainer">
                <div className={'title'}>{t('register.titleconfirmacion1')}</div>
                <div className={'confirmContent'}>
                    <div className="IconoConfirm">
                        <div className={'IconParty'}>
                            <img src={iconParty} alt={'party'}/>
                        </div>
                        <img src={props.datos.avatar.url}/>
                        <div className={'IconParty'}>
                            <img className={'rotateImage'} src={iconParty} alt={'party'}/>
                        </div>
                    </div>
                    <div className={'welcome'}>{t('register.welcome')}</div>
                    <div className={'userName'}>{props.datos.username}</div>
                    <div className={"subTitle"} dangerouslySetInnerHTML={{__html: t(textByTenant('register.textoconfirmacion'))}}>
                    </div>
                </div>
            </div>

            <RegisterNavigationComponent finish={true} handlerNextPage={props.seguintePantalla} />

        </div>
    );
}

export default Confirm;
