import React from "react";
import {Col} from "reactstrap";
import {toArray} from '@components/Kotlin/KotlinUtils';
import {useTranslation} from 'react-i18next';

const SortMenuComponent = ({displayMenu, nameOrder, showMenu, nameOrderArray, handleClickOrder, getTextSort}) => {

    const {t} = useTranslation();

    return (<Col xs={12} sm={3} lg={2}  className="OrderMenuContainer">
        <div className="OrderMenu">
            <div className="MenuCatalogueContainer" onClick={displayMenu}>
                                    <span className="MenuCatalogue orderMenuTitle">
                                        {t(nameOrder)}
                                    </span>
                <span className={"IconMenuCatalogue"}>
                                        <ion-icon name="chevron-down-outline"/>
                                    </span>
            </div>
            {showMenu && <div className="MenuDesplegableOrder">
                {nameOrderArray && toArray(nameOrderArray).map(o =>
                    <div type="button" className="MenuSubsectionOrder" key={o}
                         onClick={() => handleClickOrder(o)}>
                        <span className="button-text">{getTextSort(o)}</span>
                    </div>)}
            </div>}
        </div>
    </Col>)
};

export default SortMenuComponent;