import React from "react";
import HandleClickOutside from "../../../../../app/src/Components/DOM/Click/HandleClickOutside";
import './TermsModalComponent.css';
import {useTranslation} from 'react-i18next';

const TermsModalComponent = ({refTerms, handleTermsModal, terms}) => {
    const {t} = useTranslation();

    return (
        <div className="TermsModalBackground">
            <HandleClickOutside elementref={refTerms} callback={handleTermsModal}/>
            <div className="TermsModal" ref={refTerms}>
                <p className="TermsTitle">{t('terms.title')}</p>
                <div className="TermsContainer">
                    <div className="legal-text" dangerouslySetInnerHTML={{__html: terms}}/>
                </div>
                <div className="ButtonContainer">
                    <button onClick={handleTermsModal}
                            className="button-focused CloseButton">{t('global.button.close')}</button>
                </div>
            </div>
        </div>
    )
};

export default TermsModalComponent;