import {
  DISPLAY_SESSION_EXPIRED_MODAL,
  HIDE_SESSION_EXPIRED_MODAL,
  LOAD_TENANT_STYLES,
  TURN_OFF_LOADING_ACTION,
  TURN_ON_LOADING_ACTION,
  STORE_PRODUCT_URL,
  TOGGLE_PASSWORD_MODE,
  DISPLAY_ON_BOARDING,
  SELECT_MENU_SECTION,
  STORE_HOME_SECTION,
} from "./actionTypes";

export const turnOnLoadingActionCreator = () => ({
  type: TURN_ON_LOADING_ACTION,
});

export const turnOffLoadingActionCreator = () => ({
  type: TURN_OFF_LOADING_ACTION,
});

export const displaySessionExpiredModalActionCreator = () => ({
  type: DISPLAY_SESSION_EXPIRED_MODAL,
});

export const hideSessionExpiredModalActionCreator = () => ({
  type: HIDE_SESSION_EXPIRED_MODAL,
});

export const loadTenantStylesActionCreator = (data) => ({
  type: LOAD_TENANT_STYLES,
  data,
});

export const storeProductURLActionCreator = (data) => ({
  type: STORE_PRODUCT_URL,
  data,
});

export const togglePasswordModeActionCreator = () => ({
  type: TOGGLE_PASSWORD_MODE,
});

export const displayOnBoardingActionCreator = (data) => ({
  type: DISPLAY_ON_BOARDING,
  data,
});

export const selectMenuSection = (data) => ({
  type: SELECT_MENU_SECTION,
  data,
});

export const storeHomeSectionActionCreator = (data) => ({
  type: STORE_HOME_SECTION,
  data,
});
