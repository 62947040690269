import React from "react";
import NoResult from "@resources/Images/empty.png";
import {useTranslation} from 'react-i18next';


const NoContentComponent = () => {

    const {t} = useTranslation();

    return (<div className="NoResultsSearch">
        <img className="ImageNoResult" src={NoResult} alt={"Nofind"}/>
        <div className="NoResults">{t('catalogue.noResults')}</div>
    </div>)
};

export default NoContentComponent;