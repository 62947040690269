import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

const loggerMiddleware = createLogger();

export default function configureStore(preloadedState) {
  return createStore(preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware)));
}
