import React, {useState, useEffect} from "react";
import './ShareContentModalComponent.css';
import HandleClickOutside from "../../../../app/src/Components/DOM/Click/HandleClickOutside";
import {useTranslation} from 'react-i18next';
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {
    SOCIAL_ICON_SIZE_DESKTOP, SOCIAL_ICON_SIZE_MOBILE,
    SOCIAL_NETWORK_FACEBOOK, SOCIAL_NETWORK_TELEGRAM,
    SOCIAL_NETWORK_TWITTER,
    SOCIAL_NETWORK_WHATSAPP,
    URL_COPIED_TIMEOUT
} from "./ShareContentModalConstants";
import {SWITCHER_WIDTH} from "../../../../app/src/Utils/Constants";


const ShareContentModalComponent = ({refShare, hideShareModal, title, description}) => {
    const {t} = useTranslation();
    const url = window.location.href;
    const [URLCopied, setURLCopied] = useState(null);

    const quote = t('share.quote') + title + '\n\n' + description;

    const handleCopyUrl = () => {
        setURLCopied(!URLCopied);
    };

    useEffect(() => {
        if (URLCopied) {
            setTimeout(() => handleCopyUrl(), URL_COPIED_TIMEOUT)
        }
    }, [URLCopied, handleCopyUrl])

    const mobileVersion = window.innerWidth <= SWITCHER_WIDTH;
    const iconSize = mobileVersion ? SOCIAL_ICON_SIZE_MOBILE : SOCIAL_ICON_SIZE_DESKTOP;

    return (
        <div className="ModalBackground">
            <HandleClickOutside elementref={refShare} callback={hideShareModal}/>
            <div className="ShareModal" ref={refShare}>
                <p className="ShareTitle">{t('share')}</p>
                <div className="SocialContainer">
                    <div>
                        <FacebookShareButton
                            url={url}
                            quote={quote}>
                            <FacebookIcon size={iconSize} round className="SocialIcon"/>
                        </FacebookShareButton>
                        <p className="SocialName">{SOCIAL_NETWORK_FACEBOOK}</p>
                    </div>
                    <div>
                        <TwitterShareButton url={url}
                                            quote={quote}>
                            <TwitterIcon size={iconSize} round className="SocialIcon"/>
                        </TwitterShareButton>
                        <p className="SocialName">{SOCIAL_NETWORK_TWITTER}</p>
                    </div>
                    <div>
                        <WhatsappShareButton url={url}
                                             quote={quote}>
                            <WhatsappIcon size={iconSize} round className="SocialIcon"/>
                        </WhatsappShareButton>
                        <p className="SocialName">{SOCIAL_NETWORK_WHATSAPP}</p>
                    </div>
                    <div>
                        <TelegramShareButton url={url}
                                             quote={quote}>
                            <TelegramIcon size={iconSize} round className="SocialIcon"/>
                        </TelegramShareButton>
                        <p className="SocialName">{SOCIAL_NETWORK_TELEGRAM}</p>
                    </div>
                </div>
                {url && <div className="URLContainer">
                    <p className={mobileVersion ? "URLTextMobile" : "URLText"}>{url}</p>
                    <CopyToClipboard text={url} onCopy={() => handleCopyUrl()}>
                        <button
                            className={URLCopied ? "CopiedButton" : "CopyButton"}>{URLCopied ? t('copied.url') : t('copy.url')}</button>
                    </CopyToClipboard>
                </div>}
                <div className="ButtonContainer">
                    <button onClick={() => hideShareModal()}
                            className="ReportOptionButton button-focused CancelButton">{t('global.button.cancel')}</button>
                </div>
            </div>
        </div>
    )
};

export default ShareContentModalComponent;