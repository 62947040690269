import React from "react";
import {
    ACCESS_PLAY,
    BANNER_HIGHLIGHTED_WIDTH,
    LIVE_WIDGET_HEIGHT_RATIO,
    PLAYLIST_URL_VALUE
} from "../PlaylistConstants";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import moment from "moment";

import ImageMaxHeightRatio from "../../../../../app/src/Components/DOM/Image/ImageMaxHeightRatio";
import useOnScreen from "../../../../../app/src/Components/DOM/Screen/isVisible";
import {URL_PLAYER, URL_VIDEODETAIL} from "../../../../../app/src/Components/Routes/Location";
import VideoType from "../VideoType";
import {toArray} from '@components/Kotlin/KotlinUtils';
import VideoTag from "../VideoTag";
import degraded from '@app/Resources/Images/degradado600.png';
import {actionTypeContainer} from "../../../../../app/src/Utils/utils";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../../app/src/Resources/Multitenant/tenantConstants";


const HighlightSlideComponent = ({w, setRef, getRef, defaultBanner, access, clickCallback}) => {

    const {t} = useTranslation();
    const limitOfTags = 4;

    return (<div>
        <div className={MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem} key={w.id + "-" + w.video?.url}>
            {(w.background?.url || w.banner?.url) &&

            <div className={MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName} >
                <Link to={{
                    pathname: access === ACCESS_PLAY ? URL_PLAYER + "/" + w.id : URL_VIDEODETAIL + "/" + w.id,
                    state: {
                        url: w.video?.url,
                        type: w.video?.type,
                        id: w.id
                    }
                }} >
                <img src={!!w.background ?
                    w.background?.url:
                    w.banner?.url}
                     alt={w.title} ref={setRef(w.titulo + "-" + w.video?.url)} className={MULTITENANT_CONSTANTS[TENANT].classHeight}/>
                </Link>


            </div>}

            {(w.background?.url === null && w.poster?.url === null) &&
            <div className="PlaylistHighlight">
                <img className="PlaylistHighlight" src={defaultBanner} alt={w.title}
                     ref={setRef(w.titulo + "-" + w.video?.url + PLAYLIST_URL_VALUE)} className={MULTITENANT_CONSTANTS[TENANT].classHeight}/>
            </div>}
            <Link to={{
                pathname: access === ACCESS_PLAY ? URL_PLAYER + "/" + w.id : URL_VIDEODETAIL + "/" + w.id,
                state: {
                    url: w.video?.url,
                    type: w.video?.type,
                    id: w.id
                }
            }} className="OverContainerHome" style={{backgroundImage: "url(" + degraded + ")"}}>
                    <div className="TextHome">
                        <div className="TitleHighlight">{w.title}</div>
                        <div className={"HighlightTagsContainer"}>
                            {MULTITENANT_CONSTANTS[TENANT].showVideoTags ?
                            <><VideoType subtitle={w}/>{w.tags && toArray(w.tags).map((t,index) => {
                                 return index < limitOfTags ? <VideoTag tag={t} key={w.tag + '-' + index}/> :
                                     index === limitOfTags && '...'
                        })}</> :
                                w.subtitle
                            }
                        </div>
                        <div className="HighlightInfoContainer">
                            {w.category &&
                            <span className="Xenero">
                                {w.category}
                            </span>}
                            {w.publishDate &&
                            <span className="Data">
                                {moment(w.publishDate, 'YYYY-MM-DD').format("DD MMM YYYY")}
                            </span>}
                            {w.seasons &&
                            <span className="Temporadas">
                                {t('ficha.seasons', {seasons: w.seasons})}
                            </span>}
                            {w.rating &&
                            <span className="Idade">
                                {t('ficha.rating', {rating: w.rating})}
                            </span>}
                        </div>
                        {w.video?.url &&
                            <Link to={{
                                pathname: actionTypeContainer(access).ROUTE + "/" + w.id,
                                state: {
                                    url: w.video?.url,
                                    type: w.video?.type,
                                    id: w.id
                                }
                            }} className="ButtonAccess"
                                  onClick={clickCallback ? () => clickCallback(w.id) : ''}>
                            </Link>
                        }
                    </div>
            </Link>
        </div>
    </div>)
};

export default HighlightSlideComponent;