import React from 'react';
import {evaluateItemClass} from "../utils";
import {actionTypeContainer} from "../../../../../../app/src/Utils/utils";
import {BANNER_HEIGHT, BANNER_WIDTH} from "../../PlaylistConstants";
import VideoType from "../../VideoType";
import {Link} from "react-router-dom";
import moment from "moment";

const SportLiveCardComponent = (props) => {

    const {overOnCard, i, w, playlist, setOverOnCard, access, clickCallback, defaultBanner} = props;

    const isOnAir = () => {
        return moment(new Date(w.startTimeMs?.toNumber())).format() < moment(new Date()).format();
    }

    return (
        <div className={evaluateItemClass({i, overOnCard, playlist, itemClass:'PlaylistItemContainer'})} key={w.id + w.video?.url}
             onMouseEnter={()=> setOverOnCard(i)}
             onMouseLeave={()=> setOverOnCard(false)}
        >
            <Link to={{
                pathname: actionTypeContainer(access).ROUTE + "/" + w.id,
                state: {
                    url: w.video?.url,
                    type: w.video?.type,
                    id: w.id
                }
            }}>
                <div
                    className="PlaylistItem Live"
                    onClick={clickCallback ? () => clickCallback(w.id) : ''}
                    key={w.titulo + "-" + w.video?.url}>

                    {(w.background?.url || w.banner?.url) ?
                        <img className="PlaylistItemImage"
                             src={!!w.banner
                                 ? w.banner?.url :
                                 w.background?.url}
                             alt={w.title}/>
                        : <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                    }

                    {(w.background?.url === null && w.banner?.url === null) &&
                        <img className="PlaylistItemImage" src={defaultBanner} alt={w.title}/>
                    }
                </div>
                <div className={"textLive liveSportDesign"}>
                    <div className="PlaylistItemTitle">{w.title}</div>
                    <div className="PlaylistItemSubtitle">
                        {new Date(w.startTimeMs?.toNumber()).toLocaleString()}
                    </div>
                </div>

            </Link>
            <VideoType subtitle={w} isLiveWidget onAir={isOnAir()}/>
        </div>
        )
}

export default SportLiveCardComponent;