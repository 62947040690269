import React, {useEffect, useState} from 'react';
import "./ProductsComponent.css";
import {useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../app/src/actions/commonActions";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {detail, interops, user} from '@core';
import {DATA_LOADING_STATUS_LOADED} from "../../../../app/src/Utils/Constants";
import {compareUserProfileObjects} from "../../../../app/src/Utils/utils";

import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {toArray} from "../../../../app/src/Components/Kotlin/KotlinUtils";
import {
    URL_CANCEL_CHECKOUT,
    URL_SUCCESS_CHECKOUT,
} from "../../../../app/src/Components/Routes/Location";
import {PRODUCT_RECURRING_PERIOD, PRODUCT_RECURRING_TYPE} from "./ProductsConstants";
import Spinner from 'react-bootstrap/Spinner'


const ProductsComponent = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    let {id} = useParams();
    const outputFn = useOutput();
    const {userAppController} = props;
    const tenantStyles = useSelector(state => state.commonState.tenantStyles);

    const [userController, setUserController] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [checkoutURL, setCheckoutURL] = useState(null);
    const [loading, setLoading] = useState(null);


    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };


    useEffect(() => {
        const userCon = userAppController;
        let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));

        setUserController(userCon);

        return () => userCon.unbind(userBinding)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userModel = o => {
        if (o.latestProductCheckout !== null && o.loadingProductCheckout.name$ === DATA_LOADING_STATUS_LOADED) {
            setCheckoutURL(o.latestProductCheckout.checkoutUrl)
        }
    };

    const userOutput = o => {
    };

    useEffect(() => {
        turnOnLoading();

        const con = new detail.controller;
        let binding = con.bind(model, o => outputFn(o, output, detail));
        const ev = new detail.events.LoadContent(id);
        con.dispatch(ev)
        con.dispatch(detail.events.LogDetailView)

        return () => con.unbind(binding)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const model = (o) => {
        const formattedModel = {};

        formattedModel.loading = o.isContentLoading.name$;

        if (o.playbackSupport) {
            formattedModel.playbackSupport = interops.getKotlinName(o.playbackSupport);
        }

        if (o.playbackSupport?.products) {
            formattedModel.products = toArray(o.playbackSupport.products);
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(detailData, formattedModel)) {
            if (detailData === null) {
                turnOffLoading();
            }
            setDetailData(formattedModel)
        }
    }

    const output = () => {
    };

    const handleCheckOutProduct = productId => {
        const cancelUrl = `${window.location.origin}${URL_CANCEL_CHECKOUT}/${id}`;
        const successUrl = `${window.location.origin}${URL_SUCCESS_CHECKOUT}/${id}`;

        userController?.dispatch(new user.events.GetProductCheckout(productId, successUrl, cancelUrl));
        setLoading(productId);
    };

    useEffect(() => {
        if (checkoutURL && loading) {
            setLoading(null);
            const win = window.open(checkoutURL);
            win.focus();
            setCheckoutURL(null);
        }
    }, [checkoutURL])

    const transformPriceNumber = number => {
        const result = number / 100;
        return result.toFixed(2)
    }

    const isMonthlyPayment = product => {
        return product.recurringPeriod.name$ === PRODUCT_RECURRING_PERIOD && product.recurringType.name$ === PRODUCT_RECURRING_TYPE
    }

    const backgroundImageByTenant = () => {
            return `url(${tenantStyles?.backgroundRegister})`;
    }



    return (
        <div className={"Page"} style={{ backgroundImage:backgroundImageByTenant()}}>
            {detailData !== null && <div className={"Container"}>
                <div className={"PageHeader"}>
                    <div>
                        <ion-icon name="lock-open" id={"LockIcon"}/>
                    </div>
                    <p className={"PageTitle"}>{t('products.choose.pack')}</p>
                </div>
                <div className={"ProductsContainer"}>
                    {detailData?.products?.map((product, index) =>
                        <div className={"ProductCard"} key={product.id + '-' + index}
                             onClick={() => handleCheckOutProduct(product.id)}
                             style={{background: `url(${product?.image?.url}) no-repeat center center`,
                                 backgroundSize: 'cover'}}>
                            <div className={"Gradient"}/>
                            {loading === product.id && <div className="SpinnerContainer">
                                <Spinner animation="border" variant="primary" />
                            </div>}
                            <div className={"groupTitleDescription"}>
                                <div className={"ProductMainInfo"}>
                                    <strong className={"ProductTitle"}>{product.name}</strong>
                                    <strong className={"ProductPrice"}>
                                        {transformPriceNumber(product.price)}
                                    </strong>
                                    <strong className={"ProductCurrency"}>
                                        {product.currency} {isMonthlyPayment(product) ? `/ ${t('products.month')}` : ''}
                                    </strong>
                                </div>
                                <div className={"ProductIconText"}>
                                    {product.description}
                                </div>
                            </div>
                            {/*<div className={"ProductIconText"}>
                                <div className={"TextIconLine"}>
                                    <ion-icon name="lock-open-outline" id={"InfoLockIcon"}/>
                                    <p className={"Text"}>Acceso ilimitado</p>
                                </div>
                                <div className={"TextIconLine"}>
                                    <ion-icon name="videocam-outline" id={"InfoCamIcon"}/>
                                    <p className={"Text"}>Vídeo HD y 4K</p>
                                </div>
                                <div className={"TextIconLine"}>
                                    <ion-icon name="key-outline" id={"InfoKeyIcon"}/>
                                    <p className={"Text"}>Contenido exclusivo</p>
                                </div>
                            </div>*/}
                        </div>)}
                </div>
            </div>}
        </div>)
};

export default ProductsComponent;