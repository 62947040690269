import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./QRModalComponent.css";
import { useHistory } from "react-router-dom";
import { SWITCHER_WIDTH } from "../../Utils/Constants";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";
import { URL_VIDEODETAIL } from "../Routes/Location";

const QRModalComponent = ({ url, goBack, handleCloseQRModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [renderMobileVersion, setRenderMobileVersion] = useState(window.innerWidth <= SWITCHER_WIDTH);

  const handleCloseModal = () => {
    goBack ? history.push(URL_VIDEODETAIL + "/" + id) : handleCloseQRModal();
  };

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className="ModalBackground">
      <div className="QRModal">
        <b>{t("AR.content.warning")}</b>
        {renderMobileVersion ? (
          <div className={"ModalContent"}>
            <p>{t("AR.download.app")}</p>
            <button className="DownloadButton button-focused">
              <a href={url}>{t("global.button.download")}</a>
            </button>
          </div>
        ) : (
          <div className={"ModalContent"}>
            <div className={"QR"}>
              <QRCode value={url} includeMargin />
            </div>
            <p>{t("AR.QR.message")}</p>
          </div>
        )}
        <div className="ButtonContainer">
          <button onClick={() => handleCloseModal()} className="CancelButton button-focused">
            {goBack ? t("global.button.go.back") : t("global.button.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRModalComponent;
