import React, {useEffect, useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {useHistory, NavLink} from "react-router-dom";
import $ from 'jquery';
import {URL_LOGIN, URL_REGISTER} from '@components/Routes/Location';
import {useDispatch, useSelector} from "react-redux";
import {
    SELECTAGE,
    NAMEUSER,
    AVATAR,
    CTITOR,
    CTITOR2,
    DATAUSER,
    CATEGORY,
    CONDITIONS,
    CONFIRMATION,
    FINISHED,
    ROL_KID,
    ROL_ADULT,
    getRoleStr, getRoleObject
} from './PantallasRexistro';
import Avatar from './Avatar';
import Category from './Category';
import Conditions from './Conditions';
import Confirm from './Confirm';
import CTitor from './CTitor';
import CTitor2 from './CTitor2';
import DataUser from './DataUser';
import NameUser from './NameUser';
import SelectAge from './SelectAge';
import './Register.css';
import { signup } from '@core';

import '../common/components/HeaderRegistrationComponent';
import HeaderRegistrationComponent from "../common/components/HeaderRegistrationComponent";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {SIGNUP_MODE} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
    REGISTER_ERROR_INVALID_BIRTHDAY, REGISTER_ERROR_INVALID_CREDENTIALS,
    REGISTER_ERROR_INVALID_EMAIL,
    REGISTER_ERROR_INVALID_USERNAME, REGISTER_ERROR_UNEXPECTED_ERROR, REGISTER_ERROR_USER_CREATED,
    SIGNUP_MODE_SIMPLE
} from "./RegisterConstant";
import {
    turnOffLoadingActionCreator,
    turnOnLoadingActionCreator
} from "../../../../app/src/actions/commonActions";

let datos = {};

function Register(props) {

    const outputFn = useOutput();
    const dispatch = useDispatch();
    const {keycloak, initialized} = useKeycloak();
    /*const [pantalla, setPantalla] = useState(SELECTAGE);*/
    const [pantalla, setPantalla] = useState(SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE);
    const [pantallasAnteriores, setPantallasAnteriores] = useState([]);
    const [cancelado, setCancelado] = useState(false);
    const [controller, setController] = useState(null);
    const [datosState, setDatosState] = useState(null);
    const [roles, setRoles] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [avatares, setAvatares] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [invalidUsername, setInvalidUsername] = useState(false);
    const [invalidData, setInvalidData] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [msgErroRexistro, setMsgErroRexistro] = useState(false);

    const tenantStyles = useSelector(state => state.commonState.tenantStyles);

    let history = useHistory();

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator());
    }

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator());
    }

    //Limpeza de url
    if (props.location.pathname !== URL_REGISTER) {
        history.push(URL_REGISTER)
    }

    //Obter cliente por defecto
    useEffect(() => {
        turnOnLoading();

        const con = new signup.controller;
        let binding = con.bind(handleModel, o => outputFn(o, handleOutput, signup))
        setController(con)
        con.dispatch(signup.events.LogSignupView)

        //Idiomas
        let ev = signup.events.GetLanguages;
        con.dispatch(ev)

        //Avatares
        const evas = signup.events.GetAvatars;
        con.dispatch(evas)

        //Categorias seleccionadas
        const evis = signup.events.GetInterests;
        con.dispatch(evis)

        //Condiciones de uso
        const evac = signup.events.GetLegalConditions;
        con.dispatch(evac)

        return () => {
            con.unbind(binding);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (controller) {
            turnOffLoading();
        }
    }, [controller])





    const handleModel = (o) => {
        let a;
        let it;
        let as;
        if (o.availableRoles) {
            as = [];
            it = o.availableRoles.iterator();
            while (it.hasNext()) {
                a = it.next();
                as.push(a)
            }
            setRoles(as);
        }

        if ((!datos.rolObject && o.role) ||
            (o.role && datos.rolObject?.maxAge !== o.role?.maxAge
                && datos.rolObject?.minAge !== o.role?.minAge
                && datos.rol !== o.role)) {
            const d = datos;
            d.rolObject = o.role;
            d.rol = getRoleStr(o.role);

            if (d.rol === ROL_KID) {
                $('#root').addClass("Kid");
            } else {
                $('#root').removeClass("Kid");
            }

            datos = d;
            setDatosState(datos)
            seguintePantalla(d.rol)
        }

        if (o.availableLanguages) {
            as = [];
            it = o.availableLanguages.iterator();
            while (it.hasNext()) {
                a = it.next();
                as.push(a)
            }
            setLanguages(as);
        }

        if (o.availableAvatars) {
            as = [];
            it = o.availableAvatars.iterator();
            while (it.hasNext()) {
                a = it.next();
                as.push(a)
            }
            setAvatares(as);
        }
        if (o.availableInterests) {
            as = [];
            it = o.availableInterests.iterator();
            while (it.hasNext()) {
                a = it.next();
                as.push(a)
            }
            setCategorias(as);
        }

        if (!!o.avatar) {
            let d = datos ? datos : {};
            d.avatar = o.avatar;
            actualizarDatos(d)
        }

        if (!!o.username) {
            let d = datos;
            d.username = o.username;
            actualizarDatos(d)
        }

        if (!!o.language) {
            let d = datos;
            d.language = o.language;
            actualizarDatos(d)
        }

        if (o.firstName) {
            let d = datos;
            d.firstName = o.firstName;
            actualizarDatos(d)
        }

        if (o.lastName) {
            let d = datos;
            if (d.lastName !== o.lastName) {
                d.lastName = o.lastName;
                actualizarDatos(d)
            }
        }

        if (o.email) {
            let d = datos;
            d.email = o.email;
            actualizarDatos(d)
        }

        if (o.birthday) {
            let d = datos;
            d.birthday = o.birthday;
            actualizarDatos(d)
        }

        if (o.credentials) {
            let d = datos;
            d.credentials = o.credentials;
            actualizarDatos(d)
        }

        if (o.interests) {
            const is = [];
            it = o.interests.iterator();
            while (it.hasNext()) {
                a = it.next();
                is.push(a)
            }
            let d = datos;
            d.categorias = is;
            actualizarDatos(d)
        }

        if (o.legalConditions) {
            let d = datos;
            d.legalConditions = o.legalConditions;
            actualizarDatos(d)
        }
    }


    const handleOutput = (o) => {
        const clase = o.constructor.$metadata$.simpleName;
        if (clase === REGISTER_ERROR_INVALID_CREDENTIALS) {
            setInvalidPassword(true)
        }

        if (clase === REGISTER_ERROR_INVALID_BIRTHDAY) {
            setInvalidData(true)
        }

        if (clase === REGISTER_ERROR_INVALID_USERNAME && datos?.username) {
            setInvalidUsername(true)
        }

        if (clase === REGISTER_ERROR_INVALID_EMAIL) {
            setInvalidEmail(true)
        }

        if (clase === REGISTER_ERROR_USER_CREATED) {
            setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, CONDITIONS])));
            setPantalla(CONFIRMATION);
        }

        if (clase === REGISTER_ERROR_UNEXPECTED_ERROR) {
            setMsgErroRexistro(true)
        }
    }


    //Fluxo de pantallas
    const seguintePantalla = (rol) => {
        if (!rol) {
            rol = datos.rol;
        }
        switch (pantalla) {
            case SELECTAGE:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                if (rol === ROL_ADULT) {
                    setPantalla(DATAUSER)
                } else {
                    setPantalla(NAMEUSER)
                }
                break;
            case NAMEUSER:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                setPantalla(AVATAR)
                break;
            case AVATAR:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                if (rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) {
                    setPantalla(CATEGORY)
                } else {
                    setPantalla(CTITOR)
                }
                break;
            case CTITOR:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                //setPantalla(CTITOR2)
                setPantalla(DATAUSER)
                break;
            case CTITOR2:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                setPantalla(DATAUSER)
                break;
            case DATAUSER:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                if (rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) {
                    setPantalla(AVATAR)
                } else {
                    setPantalla(CATEGORY)
                }
                break;
            case CATEGORY:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                setPantalla(CONDITIONS)
                break;
            case CONDITIONS:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                setPantalla(CONFIRMATION)
                break;
            case CONFIRMATION:
                setPantallasAnteriores(pantallasAnteriores => Array.from(new Set([...pantallasAnteriores, pantalla])))
                setPantalla(FINISHED)
                break;
            default:
                setPantalla(SELECTAGE)
                break;
        }
    }

    const anteriorPantalla = () => {
        const pas = [...pantallasAnteriores];
        if (pas && pas.length > 0) {
            //Reseteo de datos ao cambiar de idade
            if (pas[pas.length - 1] === (SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE)) {
                resetearDatos()
            }

            setPantalla(pas[pas.length - 1]);
            pas.splice(pas.length - 1, 1)
            setPantallasAnteriores(pas)
        } else {
            resetearDatos()
            setPantalla(SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE);
            setPantallasAnteriores([]);
        }
    }

    const asignarRole = (r) => {
        if (roles) {
            let rol = getRoleObject(roles, r);
            if (rol) {
                //    let evas = new signup.events.SetRole(rol);
                //   controller.dispatch(evas);
            }
        }
    }

    const actualizarDatos = (d2) => {
        var d1 = datos;
        var d = Object.assign({}, d1, d2);
        datos = d;
        setDatosState(datos)
    }

    const resetearDatos = () => {
        datos = {};
        setDatosState(datos)
    }

    if (!initialized || !keycloak.clientId) {
        return <div className="loading"/>;
    }

    if (keycloak && keycloak.authenticated) {
        keycloak.logout();
        return <div className="loading"/>;
    }

    if (cancelado) {
        return <Redirect to={URL_LOGIN}/>;
    }

    if (pantalla === FINISHED) {
        return <Redirect to={URL_LOGIN}/>
    }

    const ctrlSetPreferredLanguage = (l) => {
        let ev = new signup.events.SetPreferredLanguage(l);
        controller.dispatch(ev)
    }
    const ctrlSetFirstName = (e) => {
        let ev = new signup.events.SetFirstName(e);
        controller.dispatch(ev)
    }
    const ctrlSetLastName = (e) => {
        let ev = new signup.events.SetLastName(e);
        controller.dispatch(ev)
    }
    const ctrlSetBirthday = (d) => {
        /*setInvalidData(false)*/
        let ev = new signup.events.SetBirthday(d);
        controller.dispatch(ev)
    }
    const ctrlSetUsername = (e) => {
        setInvalidUsername(false)
        let ev = new signup.events.SetUsername(e);
        controller.dispatch(ev)
    }

    const ctrlSetEmail = (e) => {
        setInvalidEmail(false);
        let re = /^\S+@\S+\.\S+$/;
        if (re.test(e)) {
            let ev = new signup.events.SetEmail(e);
            controller.dispatch(ev)
        } else {
            setInvalidEmail(true);
        }
    }

    const ctrlSetCredentials = (p) => {
        setInvalidPassword(false)
        let ev = new signup.events.SetCredentials(p);
        controller.dispatch(ev)
    }

    const ctrlSetRole = (r) => {
        if (datos.rolObject !== r) {
//            let ev = new signup.events.SetRole(r);
//            controller.dispatch(ev)
        }
    }

    const ctrlSetAvatar = (a) => {
        let ev = new signup.events.SetAvatar(a);
        controller.dispatch(ev)
    }

    const ctrlAddInterest = (c) => {
        let ev = new signup.events.AddInterest(c);
        controller.dispatch(ev)
    }
    const ctrlAcceptLegalConditions = (c) => {
        let ev = new signup.events.AcceptLegalConditions(c);
        controller.dispatch(ev)
        const aceptCond = signup.events.Signup;
        controller.dispatch(aceptCond);
        console.debug("Signup");
    }

    const validar = (password2) => {

        if (password2 === datos.credentials) {
            setInvalidPassword(false)
        } else {
            setInvalidPassword(true)
            return;
        }

        //Username
        if (!datos.username) {
            setInvalidUsername(true);
            return;
        } else {
            setInvalidUsername(false)
        }

        //Data
        if (!datos.birthday && SIGNUP_MODE !== SIGNUP_MODE_SIMPLE) {
            setInvalidData(true);
            return;
        } else {
            setInvalidData(false)
        }

        //Email
        if (!datos.email && SIGNUP_MODE !== SIGNUP_MODE_SIMPLE) {
            setInvalidEmail(true);
            return;
        } else {
            setInvalidEmail(false)
        }

        seguintePantalla();
    }

    return (
        <div className={"Register " + (datos.rol ? datos.rol : '')} style={{ "background-image": "url("+ tenantStyles?.backgroundRegister+")" }} >
            <div className="App-container">
                <div className="logo">
                    <NavLink to={URL_LOGIN}>
                        <div className="Logo-Barra" alt="logo"/>
                    </NavLink>
                </div>
                <div className="Register-Container">
                    <HeaderRegistrationComponent pageNumber={pantallasAnteriores.length} pantalla={pantalla}
                                                 rol={datos.rol} language={datos.language} languages={languages}
                                                 ctrlSetPreferredLanguage={ctrlSetPreferredLanguage} controller={controller}

                    />
                    {(!pantalla || pantalla === SELECTAGE) &&
                    <SelectAge asignarRole={asignarRole} ctrlSetBirthday={ctrlSetBirthday} datos={datos}
                               actualizarDatos={actualizarDatos}/>}
                    {pantalla === NAMEUSER &&
                    <NameUser datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                              anteriorPantalla={anteriorPantalla} ctrlSetUsername={ctrlSetUsername}
                              invalidUsername={invalidUsername}/>}
                    {pantalla === AVATAR &&
                    <Avatar datos={datosState} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                            anteriorPantalla={anteriorPantalla} ctrlSetAvatar={ctrlSetAvatar} avatares={avatares}/>}
                    {pantalla === CTITOR &&
                    <CTitor datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                            anteriorPantalla={anteriorPantalla}/>}
                    {pantalla === CTITOR2 &&
                    <CTitor2 datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                             anteriorPantalla={anteriorPantalla}/>}
                    {pantalla === DATAUSER &&
                    <DataUser datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                              anteriorPantalla={anteriorPantalla} ctrlSetPreferredLanguage={ctrlSetPreferredLanguage}
                              ctrlSetFirstName={ctrlSetFirstName} ctrlSetLastName={ctrlSetLastName}
                              ctrlSetBirthday={ctrlSetBirthday} ctrlSetUsername={ctrlSetUsername}
                              ctrlSetEmail={ctrlSetEmail} ctrlSetCredentials={ctrlSetCredentials}
                              ctrlSetRole={ctrlSetRole} languages={languages}
                              invalidUsername={invalidUsername} invalidData={invalidData}
                              invalidPassword={invalidPassword} invalidEmail={invalidEmail}
                              followButtonDisabled={invalidData || invalidEmail || invalidUsername || invalidPassword}
                              validar={validar}/>}
                    {pantalla === CATEGORY &&
                    <Category datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                              anteriorPantalla={anteriorPantalla} categorias={categorias}
                              ctrlAddInterest={ctrlAddInterest}/>}
                    {pantalla === CONDITIONS &&
                    <Conditions datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                                anteriorPantalla={anteriorPantalla} msgErroRexistro={msgErroRexistro}
                                setMsgErroRexistro={setMsgErroRexistro} ctrlAcceptLegalConditions={ctrlAcceptLegalConditions}/>}
                    {pantalla === CONFIRMATION &&
                    <Confirm datos={datos} actualizarDatos={actualizarDatos} seguintePantalla={seguintePantalla}
                             anteriorPantalla={anteriorPantalla}/>}
                </div>
            </div>
        </div>
    );
}

export default Register;
