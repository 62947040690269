/**
    Obten o código de idioma ou null se non hai.
    A entrada é de tipo es-ES, es_ES ou esES.
    A saída é es
*/
export const getCodeLang = function (code) {
  if (code) {
    if (code.length > 2) {
      return code.substring(0, 2).toLowerCase();
    }
    return code.toLowerCase();
  } else {
    return null;
  }
};
