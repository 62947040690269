import React from 'react';
import ReactStars from "react-rating-stars-component";
import {useTranslation} from 'react-i18next';


 const VideoRating = ({w}) => {

     const {t} = useTranslation();

    return (<>
                <ReactStars
                    classNames={"HoverRating"}
                    value={w.score}
                    edit={false}
                    size={20}
                    color={"#ffffff50"}
                    activeColor={"#FFFFFF"}
                    emptyIcon={<ion-icon name="star-outline"/>}
                    halfIcon={<ion-icon name="star-half"/>}
                    filledIcon={<ion-icon name="star"/>}
                    isHalf={false}
                />
                {<div>
                    {w?.score && w?.score !== 0 ?
                        <p className={"HoverRatingScore"}>{w.score}</p> :
                        <p style={{fontSize: "10px"}}
                           className={"HoverRatingScore"}>{t('playlist.rating')}</p>}
                </div>}

    </>)
}

export default VideoRating;