import { URL_CONTENT, URL_PLAYER, URL_VIDEODETAIL } from "../Components/Routes/Location";

export const WIDGET_TYPE_HIGHLIGHT = "DESTACADO";
export const WIDGET_TYPE_LIVE = "LIVE";
export const WIDGET_TYPE_LIVE_2 = "LIVE_2";
export const WIDGET_TYPE_LIVE_VERTICAL = "LIVEVERTICAL";
export const WIDGET_TYPE_GROUP_WIDGET = "GROUPWIDGET";
export const WIDGET_TYPE_CIRCULAR = "CIRCULAR";
export const WIDGET_TYPE_NEWS = "NEWS";

export const DATA_LOADING_STATUS_LOADED = "LOADED";
export const DATA_LOADING_STATUS_LOADING = "LOADING";

export const SWITCHER_WIDTH = 768;

export const TVG = "TVG";

export const ANIMATION_GET_GOAL_PLAY = "playingAnimationGetGoal";
export const ANIMATION_GET_GOAL_ACHIEVEMENTS = "achievements";
export const ANIMATION_GET_GOAL_REWARDS = "rewards";

export const TYPE_REWARD_BADGE = "badge";
export const TYPE_REWARD_CERT = "cert";
export const TYPE_REWARD_HOOK = "hook";

export const WIDGET_ACTION_PLAY = "PLAY";
export const WIDGET_ACTION_DETAIL = "DETAIL";
export const WIDGET_ACTION_FULL_LIST = "FULL_LIST";

export const ROUTES_BY_WIDGET_ACTION = {
  [WIDGET_ACTION_PLAY]: {
    ROUTE: URL_PLAYER,
    TEXT: "seeMore",
  },
  [WIDGET_ACTION_DETAIL]: {
    ROUTE: URL_VIDEODETAIL,
    TEXT: "seeDetail",
  },
  [WIDGET_ACTION_FULL_LIST]: {
    ROUTE: URL_CONTENT + "/widget",
    TEXT: "seeDetail",
  },
};

export const ANDROID_DEVICES = ["Android"];
export const IOS_DEVICES = ["iPhone", "iPod", "iPad"];

export const DEVICE_IPHONE = "iPhone";
export const DEVICE_IPAD = "iPad";
export const DEVICE_IPOD = "iPod";
export const DEVICE_ANDROID = "Android";
export const DEVICE_IOS = "iOS";

export const DEVICES = {
  [DEVICE_ANDROID]: DEVICE_ANDROID,
  [DEVICE_IPHONE]: DEVICE_IOS,
  [DEVICE_IPOD]: DEVICE_IOS,
  [DEVICE_IPAD]: DEVICE_IOS,
};

export const VIDEO_TYPE_VOD_360 = "VOD_360";
export const VIDEO_TYPE_LIVE = "LIVE";
export const VIDEO_TYPE_LIVE_TIIVII = "LIVE_TIIVII";
export const VIDEO_TYPE_VOD_FORKED = "VOD_FORKED";
export const VIDEO_TYPE_VOD_CONTAINER = "CONTAINER";
export const VIDEO_TYPE_VOD_AR = "AR";
export const VIDEO_TYPE_VOD_VR = "VR";

export const TIME_ZONE_EUROPE_BERLIN = "Europe/Berlin";
export const DEFAULT_TIME_ZONE = TIME_ZONE_EUROPE_BERLIN;
