import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import  { Redirect } from 'react-router-dom'
import {ranking, user} from '@core';
import './Ranking.css';

import RankingDesktopComponent from "./RankingComponents/RankingDesktopComponent";
import RankingMobileComponent from "./RankingComponents/RankingMobileComponent";
import {arrayFormatter, compareUserProfileObjects} from "../../../../app/src/Utils/utils";
import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import {DATA_LOADING_STATUS_LOADED, SWITCHER_WIDTH} from "../../../../app/src/Utils/Constants";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {toArray} from "../../../../app/src/Components/Kotlin/KotlinUtils";
import {MULTITENANT_CONSTANTS, TENANT} from "../../../../app/src/Resources/Multitenant/tenantConstants";

function Ranking(props) {
    const dispatch = useDispatch();
    const {userAppController} = props;
    const refCertificate = useRef(null);
    const outputFn = useOutput();
    const [rankingData, setRankingData] = useState(null);
    const [error, setError] = useState(false);
    const [renderMobileVersion, setRenderMobileVersion] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [displayCertificate, setDisplayCertificate] = useState(null);
    const loading = useSelector(state => state.commonState.loading);

    let con = new ranking.controller;

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    }

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    }

    useEffect(() => {

        turnOnLoading();

        let binding = con.bind(model, o => outputFn(o, output, ranking));
        con.dispatch(ranking.events.ReloadUserStats);

        const userCon = userAppController;
        let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));
        con.dispatch(user.events.LogUserProfileView)

        return () => {
            con.unbind(binding);
            userCon.unbind(userBinding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResize = () => {
        setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [window.innerWidth])

    const model = (o) => {

        const formattedModel = {};

        formattedModel.userRanking = o.userRanking;
        formattedModel.closestAchievement = o.closestAchievement;
        formattedModel.userAvatar = o.userAvatar;
        formattedModel.loading = o.loadingStats.name$;

        if (o.rankings) {
            formattedModel.rankings = arrayFormatter(o.rankings);
        }

        if (o.achievements) {
            formattedModel.achievements = arrayFormatter(o.achievements);
        }

        if (o.rewards) {
            formattedModel.rewards = toArray(o.rewards);
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(rankingData, formattedModel)) {
            if (rankingData === null && loading) {
                turnOffLoading()
            }
            setRankingData(formattedModel)
        }
    }

    const output = (o) => {
        if (o.error) {
            setError(true)
        }
    }

    const userModel = (o) => {
        const formattedModel = {};
        formattedModel.firstName = o.firstName;
        formattedModel.lastName = o.lastName;
        formattedModel.userName = o.username;

        formattedModel.loading = o.loadingUser.name$;

        if (o.language) {
            con.dispatch(ranking.events.ReloadUserStats)
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(userProfile, formattedModel)) {
            if (userProfile === null && loading) {
                turnOffLoading()
            }
            setUserProfile(formattedModel)
        }
    }

    const userOutput = o => {
        if (o.error) {
            setError(true)
        }
    }

    const toggleCertificateModal = reward => {
        if (reward) {
            const data = {
                name: reward.name,
                user: `${userProfile.firstName} ${userProfile.lastName}`
            };
            setDisplayCertificate(data)
        } else {
            setDisplayCertificate(null)
        }
    };


        return ( !!MULTITENANT_CONSTANTS[TENANT].gamification ?
                <div className="Ranking">
                    {rankingData !== null && <div className="Ranking-container">
                        {renderMobileVersion ?
                            <RankingMobileComponent rankingData={rankingData} userName={userProfile.userName}
                                                    renderMobileVersion={renderMobileVersion}
                                                    toggleCertificateModal={toggleCertificateModal}/> :
                            <RankingDesktopComponent rankingData={rankingData} userName={userProfile.userName}
                                                     toggleCertificateModal={toggleCertificateModal}/>}
                    </div>}
                </div>
            : <Redirect to='/'  />
    );
}

export default Ranking;