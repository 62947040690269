import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationContainer, NotificationManager } from "react-notifications";

import "react-notifications/lib/notifications.css";
import "./Alert.css";

/**
 * Display alert messages.
 *
 * @param props.type alert type (info, success, warning, error). Default 'error'
 * @param props.title alert title
 * @param props.text alert message
 * @param props.time time to disappear in ms (0 to don't disappear never). Default 5000 (5 seconds)
 * @param props.showtitle must show title. Default true
 * @param props.showtext must show text. Default true
 * @param props.callback callback function on show, include used params
 * @param props.onclick function on click
 */
function AlertNotification(props) {
  const { t } = useTranslation();

  useEffect(() => {
    let type = props.type ? props.type : "error";
    let time = props.time ? props.time : 5000;
    let showtitle = props.showtitle != null ? props.showtitle : true;
    let showtext = props.showtext != null ? props.showtext : true;

    let params = {
      type: type,
      title: props.title,
      text: props.text,
      time: time,
      onclick: props.onclick,
      callback: props.callback,
      showtitle: showtitle,
      showtext: showtext,
    };

    //Limpar notificacións previas
    NotificationManager.listNotify.forEach((n) => NotificationManager.remove({ id: n.id }));

    createNotification(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNotification = (p) => {
    switch (p.type) {
      case "info":
        if (!p.text) {
          p.text = t("notification.info.msg");
        }
        if (!p.title) {
          p.title = t("notification.info.title");
        }
        NotificationManager.info(p.showtext ? p.text : "", p.showtitle ? p.title : "", p.time, p.onclick);
        break;
      case "success":
        if (!p.text) {
          p.text = t("notification.success.msg");
        }
        if (!p.title) {
          p.title = t("notification.success.title");
        }
        NotificationManager.success(p.showtext ? p.text : "", p.showtitle ? p.title : "", p.time, p.onclick);
        break;
      case "warning":
        if (!p.text) {
          p.text = t("notification.warning.msg");
        }
        if (!p.title) {
          p.title = t("notification.warning.title");
        }
        NotificationManager.warning(p.showtext ? p.text : "", p.showtitle ? p.title : "", p.time, p.onclick);
        break;
      case "error":
        if (!p.text) {
          p.text = t("notification.error.msg");
        }
        if (!p.title) {
          p.title = t("notification.error.title");
        }
        NotificationManager.error(p.showtext ? p.text : "", p.showtitle ? p.title : "", p.time, p.onclick);
        break;
      default:
        return null;
    }
    if (p.callback) {
      p.callback(p);
    }
  };

  return (
    <>
      {!props.isPlaylist && (
        <div className={"Alert"}>
          <NotificationContainer />
        </div>
      )}
    </>
  );
}

export default AlertNotification;
