import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Provider } from "react-redux";
import { store } from "@app/Utils/utils";
import keycloak from "./Utils/keycloak";
import { KeycloakProvider } from "@react-keycloak/web";

registerLocale("es", es);
setDefaultLocale("es");

const initConfig = {
  checkLoginIframe: false,
};
ReactDOM.render(
  <KeycloakProvider keycloak={keycloak} initConfig={initConfig}>
    <Provider store={store}>
      <React.StrictMode>
        <App className="loader" />
      </React.StrictMode>
    </Provider>
  </KeycloakProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
