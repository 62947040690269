export const PLAYER_TYPE_AR = 'AR';

export const PLAYBACK_SUPPORT_STATUS_RATING = 'Rating';
export const PLAYBACK_SUPPORT_STATUS_READY = 'Ready';
export const PLAYBACK_SUPPORT_STATUS_NO_PLATFORM_SUPPORT = 'Unsupported.NoPlatformSupport';
export const PLAYBACK_SUPPORT_STATUS_MISSING_IFRAME = 'Unavailable.MissingIframe';
export const PLAYBACK_SUPPORT_STATUS_BUY_DISABLED = 'Unavailable.ProductBuyoutDisabled';
export const PLAYBACK_SUPPORT_STATUS_USER_SESSION_REQUIRED = 'Locked.UserSessionRequired';
export const PLAYBACK_SUPPORT_STATUS_LOCKED_RATING = 'Locked.Rating';
export const PLAYBACK_SUPPORT_STATUS_MISSING_PRODUCT = 'Locked.MissingProduct';
export const PLAYBACK_SUPPORT_STATUS_MISSING_ACHIEVEMENTS = 'Locked.MissingAchievements';





export const PLAYBACK_LOCK_REASON = 'PlaybackLockReason';
export const PLAYBACK_LOCK_REASON_NOT_PURCHASED = 'ProductNotPurchased';
export const PLAYBACK_LOCK_REASON_RATING_LOCKED = 'RatingLocked';
export const PLAYBACK_LOCK_REASON_GUEST = 'ContentLockedForGuests';
export const PLAYBACK_LOCK_REASON_RATING = 'ContentLockedByRating';