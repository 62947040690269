import React, {useEffect} from "react";
import {Row, Col} from 'reactstrap';
import {useDispatch} from "react-redux";
import UserInfoComponent from "./components/UserInfoComponent/UserInfoComponent";
import AchievementComponent from "./components/AchievementsComponent";
import RankingListComponent from "./components/RankingListComponent";
import {turnOffLoadingActionCreator} from "../../../../../app/src/actions/commonActions";

const RankingDesktopComponent = ({rankingData, toggleCertificateModal, userName}) => {

    const dispatch = useDispatch();

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    useEffect(() => {
        turnOffLoading();
    },[]);

    return (
        <Row>
            <Col xs="12" md="12" lg="6">
                <div className="Datos-usuario">
                    <UserInfoComponent userAvatar={rankingData.userAvatar} userRanking={rankingData.userRanking}
                                       closestAchievement={rankingData.closestAchievement} userName={userName}/>
                </div>
                <div className="Logros">
                    <AchievementComponent achievements={rankingData.achievements} rewards={rankingData.rewards}/>
                </div>
            </Col>
            <Col xs="12" md="12" lg="6">
                <RankingListComponent rankings={rankingData.rankings} userAvatar={rankingData.userAvatar}
                                      userRanking={rankingData.userRanking}/>
            </Col>
        </Row>
    )
};

export default RankingDesktopComponent;