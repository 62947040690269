import React from 'react';
import {useParams} from "react-router-dom";

import Player from './Player'

import './Player.css';

function PlayerPage() {
    let {id} = useParams();

    return (
        <div className="PlayerPage">
            <Player theme="vjs-theme-forest" id={id}/>
        </div>
    );
}

export default PlayerPage;
