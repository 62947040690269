export const DETAIL_PLAYABLE_DISABLED = 'DISABLED';
export const DETAIL_PLAYABLE_DIRECT = 'DIRECT';
export const DETAIL_PLAYABLE_CHILDREN = 'CHILDREN';

export const PIXEL_UNIT = 'px';

export const METADATA_CONTENT_REPORTED = 'ContentReported';
export const METADATA_GAME_ACTION_SENT = 'GameActionSent';

export const DETAIL_TYPE_CONTAINER = 'CONTAINER';
export const DETAIL_TYPE_VOD = 'VOD';

