import React from "react";
import {Row, Col} from 'reactstrap';
import {useTranslation} from 'react-i18next';

import iconTrophy from '@app/Resources/Images/icon_trophy.svg';
import '../../Ranking.css';


const RankingListComponent = ({rankings, renderMobileVersion}) => {

    const {t} = useTranslation();

    return (
        <div className="Listado-ranking">
            {!renderMobileVersion && <p className="Logros-title">{t('ranking.ranking')}</p>}
            {rankings && rankings.map((a, index) =>
                <Row className="List-logros" style={{marginLeft: 0, marginRight: 0}} key={index}>
                    <Col xs="1" style={{padding: 0}}>
                        <span className="Posicion-user">{a.position}</span>
                    </Col>
                    <Col xs="2" className="Avatar-list" style={{padding: 0}}>
                        <div className={"Avatar-imaxe-container-list"}>
                        </div>
                    </Col>
                    <Col xs="6" style={{padding: 0}}>
                        <div className="Nombre-user">{a.username}</div>
                    </Col>
                    <Col xs="2" style={{padding: 0}}>
                        <span className="Total">{a.points}</span>
                    </Col>
                    <Col xs="1" style={{padding: 0}}>
                        <img className={"Icono-trophy"}
                             src={iconTrophy}
                            alt={'icon'}
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
};

export default RankingListComponent;