import React, {useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {interops, user} from '@core';

let catIni = null;

const SelectCategoriesComponent = (props) => {

    const {userProfile, con} = props;
    const [controller, setController] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);
    const [categoriasUnselect, setCategoriasUnselect] = useState([])
    const [advise, setAdvise] = useState(false);

    const {t} = useTranslation();


    useEffect(() => {
        let index ='';
        let catsUnselect = []
        categorias.map ((categoryToSelect) => {
            index = categoriesSelected.length > 0 &&
                categoriesSelected.map((categorySelected) => categorySelected.name).indexOf(categoryToSelect.name) !== -1;
            if (!index) {
                catsUnselect.push(categoryToSelect);
            }
        })
        setCategoriasUnselect(catsUnselect);
    }, [categoriesSelected, categorias]);

    useEffect(() => {
        if (categoriesSelected.length === 0) {
            setCategoriesSelected(userProfile?.interests);
        }
    },[userProfile])


    useEffect(() => {
        let binding = con.bind(model, output);

        const evas = user.events.GetAvailableInterests;
        con.dispatch(evas)
        setController(con)

        catIni = null;

        return () => {
            con.unbind(binding);
        }
    }, []);

    const model = (o) => {
        if (o.availableInterests) {
            let as = [];
            let it = o.availableInterests.iterator();
            while (it.hasNext()) {
                let a = it.next();
                as.push(a)
            }
            setCategorias(as);
        }
        if (o.interests) {
            let as = [];
            let it = o.interests.iterator();
            while (it.hasNext()) {
                let a = it.next();
                as.push(a)
            }
        }
    }

    const output = () => {}

    const actualizarDatos = (d2) => {
        setCategoriesSelected(d2);

        let ev = new user.events.UpdateInterest(interops.toKotlinList(d2));
        controller.dispatch(ev);
    }

    const toggleCategory = (categorySelected) => {
        setAdvise(false);
        let index = categoriesSelected?.map(function(categoryUser) { return categoryUser.name; }).indexOf(categorySelected.name);
        let categoriesUser = [...categoriesSelected];
        if (index !== -1) {
            categoriesUser.splice(index, 1);
        } else {
            categoriesUser = [...categoriesSelected, categorySelected];
        }
        if (categoriesUser.length > 0) {
            userProfile.interests = categoriesUser;
            actualizarDatos(categoriesUser);
        } else {
            setAdvise(true);
        }
    }

    return (<div className="Intereses-usuario">
        <h2 className={"Title-bold"}>{t('perfil.intereses')}</h2>
        <Row>
            <Col xs="12" className={'ProfileInterestsList ProfileSelectedInterestsList'}>
                {!!categoriesSelected && categoriesSelected.map((category) =>
                    <div style={{backgroundImage: "url(" + category?.image?.url + ")"}}
                         className="Interes" onClick={()=> toggleCategory(category)} key={category.id}>{category.name}</div>
                )}
            </Col>
            <Col xs="12" className={'ProfileInterestsList'}>
                {!!categoriasUnselect && categoriasUnselect.map((category) =>
                    <div className="Interes Unselect" onClick={()=> toggleCategory(category)} key={category.id}>{category.name}</div>
                )}
            </Col>
        </Row>
        { !!advise && <div className={"textAlert"}>{t('register.interestAlone')}</div>}
    </div>)
}

export default SelectCategoriesComponent;