import React, { useEffect, useState } from "react";
import { BarraSuperior } from "./components/barraSuperior/BarraSuperior";
import { Col } from "reactstrap";
import "./Menu.css";
import { menu, user } from "@core";
import { SECTION_URL_BY_NAME, URL_HOME } from "../../Components/Routes/Location";
import MenuComponent from "./components/menuComponent/MenuComponent";
import { DATA_LOADING_STATUS_LOADED, SWITCHER_WIDTH } from "../../Utils/Constants";
import { NotificationsProvider } from "./Context/NotificationsProvider";
import useOutput from "../../Utils/Hooks/useOutput";
import { compareUserProfileObjects } from "../../Utils/utils";
import { selectMenuSection, storeHomeSectionActionCreator } from "../../actions/commonActions";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";

function Menu(props) {
  const { homeController, userAppController, menuAppController } = props;
  const dispatch = useDispatch();
  const outputFn = useOutput();

  const locale = localStorage.getItem("petsigo-locale");

  const [controller, setController] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const [renderMobileVersion, setRenderMobileVersion] = useState(null);

  useEffect(() => {
    const con = menuAppController;
    let binding = con.bind(menuModel, (o) => outputFn(o, menuOutput, menu));

    setController(con);

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (controller) {
      const userCon = userAppController;
      let userBinding = userCon.bind(userModel, (o) => outputFn(o, userOutput, user));
      userCon.dispatch(user.events.LogUserProfileView);

      return () => {
        userCon.unbind(userBinding);
      };
    }
  }, [controller]);

  const userModel = (o) => {
    if (controller && o.language && o.language !== locale) {
      controller.dispatch(menu.events.Reload);
    }
    if (o.language && o.language !== "system" && o.language !== "sys" && o.language !== i18n.language) {
      localStorage.setItem("petisgo-locale", o.language);
      i18n.changeLanguage(o.language);
    }
  };

  const userOutput = (o) => {};

  const menuModel = (o) => {
    const formattedModel = {};

    formattedModel.activatedNotifications = true;
    formattedModel.loading = o.loadingSections.name$;

    if (o.sections) {
      const as = [];
      const it = o.sections.iterator();

      while (it.hasNext()) {
        const a = {};
        a.section = it.next();
        a.url = SECTION_URL_BY_NAME[a.section.type.name$.toUpperCase()] || URL_HOME;

        as.push(a);
      }

      formattedModel.sections = as;
    }

    if (o.studioUrl) {
      formattedModel.studioUrl = o.studioUrl;
    }

    if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(menuData, formattedModel)) {
      const homeSections = formattedModel.sections.filter((s) => s.url === URL_HOME);
      if (!!homeSections?.length) {
        const homeSection = homeSections[0].section.iconId;
        dispatch(storeHomeSectionActionCreator(homeSection));
      }

      setMenuData(formattedModel);
    }
  };

  const menuOutput = () => {};

  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const handleToggleNotifications = () => {};

  const handleChangeSection = (section) => {
    dispatch(selectMenuSection(section));
  };

  return (
    <div className="Menu">
      {menuData && (
        <div>
          <BarraSuperior
            sections={menuData?.sections}
            clase={"BarraSuperiorUsuario"}
            homeParentController={homeController}
            handleChangeSection={handleChangeSection}
            {...props}
          />
          <NotificationsProvider>
            {
              /*loadClasses( */
              <MenuComponent
                sections={menuData?.sections}
                studioUrl={menuData?.studioUrl}
                controller={controller}
                activatedNotifications={menuData.activatedNotifications}
                handleToggleNotifications={handleToggleNotifications}
                renderMobileVersion={renderMobileVersion}
                homeParentController={homeController}
                handleChangeSection={handleChangeSection}
              />
              /*)*/
            }{" "}
          </NotificationsProvider>
        </div>
      )}
    </div>
  );
}

export default Menu;
