import { URL_NAME_LIVE, URL_NAME_PROFILE, URL_NAME_SEARCH } from "../../Components/Routes/Location";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";

export const MENU_ID_LIVE = "live";
export const MENU_SCREEN_ID_HOME = 2;
export const MENU_EXCLUDED_SECTIONS_BY_ID = [URL_NAME_LIVE, URL_NAME_PROFILE, URL_NAME_SEARCH];

export const RANKING_SECTION = {
  section: {
    iconId: "podium",
    name: "Ranking",
    screenId: null,
    id: "ranking",
    type: { name$: "RANKING", ordinal$: 0 },
  },
  url: "/ranking",
};

export const STUDIO_SECTION = (url) => {
  return {
    section: {
      iconId: "color-palette-sharp",
      name: MULTITENANT_CONSTANTS[TENANT]?.studioSectionName,
      screenId: null,
      type: { name$: "STUDIO", ordinal$: 0 },
    },
    url: url,
  };
};

export const CONFIGURATION_SECTION = {
  section: {
    imgIcon: MULTITENANT_CONSTANTS[TENANT]?.configurationSectionImage,
    iconId: "settings-sharp",
    name: "menu.menu_settings",
    screenId: null,
    type: { name$: "PROFILE", ordinal$: 0 },
  },
  url: "/user",
};

export const LOGOUT_SECTION = {
  section: {
    imgIcon: MULTITENANT_CONSTANTS[TENANT]?.logoutSectionImage,
    iconId: "exit-sharp",
    name: "Log out",
    screenId: null,
    type: { name$: "LOGOUT", ordinal$: 0 },
  },
  url: "",
};
