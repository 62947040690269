import React, { useEffect, useState } from "react";
import "./OnBoardingComponent.css";
import Carousel from "react-bootstrap/Carousel";
import { DEVICE_DESKTOP, DEVICE_MOBILE, ON_BOARDING_SLIDES, THEME_DARK, THEME_LIGHT } from "./OnBoardingConstants";
import { turnOffLoadingActionCreator } from "../../../actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { SWITCHER_WIDTH } from "../../../Utils/Constants";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { TENANT, TENANT_PETISGO } from "../../../Resources/Multitenant/tenantConstants";
import Link from "@material-ui/core/Link";

const OnBoardingComponent = ({ handleSkipOnBoarding }) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [device, setDevice] = useState(DEVICE_DESKTOP);
  const [theme, setTheme] = useState(null);
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  useEffect(() => {
    turnOffLoading();
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleNext = () => {
    setIndex((index) => index + 1);
  };

  const handleBack = () => {
    setIndex((index) => index - 1);
  };

  const handleResize = () => {
    setDevice(window.innerWidth <= SWITCHER_WIDTH ? DEVICE_MOBILE : DEVICE_DESKTOP);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (keycloak) {
      setTheme(keycloak.clientId);
    }
  }, [keycloak]);

  const backgroundImageByTenant = () => {
    if (TENANT !== TENANT_PETISGO || (TENANT === TENANT_PETISGO && theme !== THEME_DARK))
      return `url(${tenantStyles?.backgroundRegister})`;
  };

  return (
    <div className={"onBoardingContainer"} style={{ backgroundImage: backgroundImageByTenant() }}>
      {device === DEVICE_DESKTOP && (
        <div className={"logoContainer"}>
          <div className="onBoardingLogo" alt="logo" />
        </div>
      )}
      <div className={"carouselContainer"}>
        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={false} id={"carousel"}>
          {!!ON_BOARDING_SLIDES[TENANT] &&
            ON_BOARDING_SLIDES[TENANT][device]?.map((slide, index) => (
              <Carousel.Item className={"carouselItem"} key={slide.title + "_" + index}>
                <img
                  className={"carouselImage"}
                  src={TENANT === TENANT_PETISGO ? slide.img[theme] : slide.img}
                  alt={`slider-img-${index}`}
                />
                <div className={"carouselTextContainer"}>
                  <h3 className={"slideTitle"}>{t(slide.title)}</h3>
                  <h6 className={"slideText"}>{t(slide.text)}</h6>
                </div>
              </Carousel.Item>
            ))}
        </Carousel>
        <div className={"buttonContainer"}>
          <div className={"buttonRow"}>
            <button onClick={() => handleBack()} disabled={index === 0} className="BackButton button-focused">
              {t("global.button.back")}
            </button>
            {!!ON_BOARDING_SLIDES[TENANT] && index === ON_BOARDING_SLIDES[TENANT][device].length - 1 ? (
              <button onClick={() => handleSkipOnBoarding(true)} className="NextButton button-focused">
                {t("global.button.finish")}
              </button>
            ) : (
              <button onClick={() => handleNext()} className="NextButton button-focused">
                {t("global.button.next")}
              </button>
            )}
          </div>

          <Link onClick={() => handleSkipOnBoarding(true)} className={"SkipButton"}>
            {t("global.button.skip")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingComponent;
