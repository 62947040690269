import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import AlertNotification from '@components/GUI/Alert/Alert';
import {toArray} from '@components/Kotlin/KotlinUtils';
import {URL_VIDEODETAIL} from '@components/Routes/Location';
import Breadcrumb from "@components/GUI/Breadcrumb/Breadcrumb";
import {catalogue, user} from '@core'

import {TEXT_NAME_BEGINNING} from "../../../CatalogueConstants";

import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from "../../../../app/src/actions/commonActions";
import {compareUserProfileObjects} from "../../../../app/src/Utils/utils";
import SectionsMenuComponent from "./components/SectionsMenuComponent";
import SubsectionsMenuComponent from "./components/SubsectionsMenuComponent";
//import SearchComponent from "./components/SearchComponent";
import SortMenuComponent from "./components/SortMenuComponent";
import NoContentComponent from "./components/NoContentComponent";
import ContentComponent from "./components/ContentComponent";
import SelectedContentComponent from "./components/SelectedContentComponent";

import './Catalogo.css';
import "react-multi-carousel/lib/styles.css";
import useOutput from "../../../../app/src/Utils/Hooks/useOutput";
import {DATA_LOADING_STATUS_LOADED} from "../../../../app/src/Utils/Constants";


const Catalogo = (props) => {

    const {userAppController} = props;
    const outputFn = useOutput();
    const history = useHistory();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    let con = new catalogue.controller;
    let nodesDefault = [];

    const [catalogueData, setCatalogueData] = useState(null);
    const [controller, setController] = useState(null);
    const [error, setError] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [nameOrder, setNameOrder] = useState(null);
    const [showNoContent, setShowNoContent] = useState(true);

    const changeShowNoContent = () => {
        setShowNoContent(false);
    }

    useEffect(() => {
        !catalogueData?.contents && setShowNoContent(true);
    },[catalogueData])


    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    useEffect(() => {
        turnOnLoading();

        setShowMenu(false)
        setNameOrder("catalogue.most_recent_first")

        let binding = con.bind(model, o => outputFn(o, output, catalogue));
        con.dispatch(catalogue.events.LogCatalogueView)
        setController(con);

        return () => {
            con.unbind(binding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (controller) {
            const userCon = userAppController;
            let userBinding = userCon.bind(userModel, o => outputFn(o, userOutput, user));
            userCon.dispatch(user.events.LogUserProfileView)

            return () => {
                userCon.unbind(userBinding);
            }
        }
    }, [controller])

    const model = (o) => {
        const formattedModel = {};

        formattedModel.hasMoreContents = o.hasMoreContents;
        formattedModel.nameOrderArray = o.availableSorts;
        formattedModel.selectedNodeEnum = o.areNodesLoading.name$;
        formattedModel.selectedContentEnum = o.areContentsLoading.name$;

        if (o.nodeTree !== null) {
            if (o.selectedNode !== null) {
                nodesDefault = o.nodeTree.getPath(o.selectedNode.coordinates)
                formattedModel.selectedNodesDefault = toArray(nodesDefault);
            }

            formattedModel.nodeTree = o.nodeTree
            formattedModel.selectedNode = toArray(o.nodeTree.rootNodes).find(node => node.value.nodeName === toArray(nodesDefault)[0]);
        }
        if (o.nodeContents !== null) {
            formattedModel.contents = toArray(o.nodeContents.asList());
        }
        if (o.selectedContent) {
            formattedModel.selectedContentChildren = toArray(o.selectedContent.children);
            formattedModel.selectedContent = o.selectedContent;
        } else {
            formattedModel.selectedContentChildren = null;
            formattedModel.selectedContent = null;
        }

        if (((formattedModel.selectedContent && formattedModel.selectedContentEnum === DATA_LOADING_STATUS_LOADED) ||
            (!formattedModel.selectedContent && formattedModel.selectedNodeEnum === DATA_LOADING_STATUS_LOADED))
            && compareUserProfileObjects(catalogueData, formattedModel)) {
            setCatalogueData(formattedModel)
        }
    }

    const output = (o) => {
        if (o.error) {
            setError(true)
        }
    }

    const userModel = o => {
        if (o.language && controller) {
            controller.dispatch(catalogue.events.Reload);
        }
    };

    const userOutput = o => {

    };

    const createBreadcrumbCategorias = (nodoarray, nodosel, level, breadcrumbs) => {
        let b = {};
        let nodo = {};

        nodoarray.forEach((n) => {
            if (nodosel[level] === n.value?.nodeName) {
                b.text = n.value?.nodeName;
                if (nodosel.length >= level + 1) {
                    let onclick = null;
                    if (level === 0) {
                        onclick = () => selectNodeParent(n.value?.nodeName);
                    }
                    if (level === 1) {
                        onclick = () => selectNodeChildren(n.value?.nodeName);
                    }
                    b.onclick = onclick;
                }
                nodo = n;
            }
        })

        if (nodo.children && nodosel.length > level + 1) {
            breadcrumbs.push(b)
            return breadcrumbs.concat(createBreadcrumbCategorias(toArray(nodo.children), nodosel, level + 1, breadcrumbs))
        } else {
            return [b];
        }
    }

    const createBreadcrumbSeriado = (nodocontent, breadcrumbs) => {
        const b = {};

        b.text = nodocontent?.value?.title;
        b.onclick = null;

        breadcrumbs.push(b)
        return breadcrumbs;
    }

    const nodeToBreadcrumb = () => {
        let breadcrumbs = [];

        if (catalogueData.nodeTree && catalogueData.selectedNodesDefault) {
            breadcrumbs = createBreadcrumbCategorias(toArray(catalogueData.nodeTree?.rootNodes),
                toArray(catalogueData.selectedNodesDefault), 0, []);
            breadcrumbs = createBreadcrumbSeriado(catalogueData.selectedContent, breadcrumbs);
        }

        return breadcrumbs;
    }


    const selectNodeParent = (nodeSelected) => {
        let arrayTemp = [];
        turnOnLoading();
        if (nodeSelected !== null && nodeSelected !== undefined) {
            const newCatalogueData = {...catalogueData}
            arrayTemp = toArray(catalogueData.nodeTree?.rootNodes).find(node => node.value.nodeName === nodeSelected)
            newCatalogueData.selectedNode = arrayTemp;
            const lwl = new catalogue.events.LoadNode(arrayTemp.coordinates);
            controller.dispatch(lwl);
            newCatalogueData.contents = null;
            setCatalogueData(newCatalogueData);
            controller.dispatch(catalogue.events.DeselectContent);
        }

    }

    const selectNodeChildren = (nodeSelected) => {
        turnOnLoading();
        if (nodeSelected !== null && nodeSelected !== undefined) {
            toArray(catalogueData.nodeTree?.rootNodes).map((n) =>
                toArray(n.children).map((c) => {
                        if (nodeSelected === c.value.nodeName) {
                            const lwl = new catalogue.events.LoadNode(c.coordinates);
                            controller.dispatch(lwl)
                            const newCatalogueData = {...catalogueData}
                            newCatalogueData.contents = null;
                            setCatalogueData(newCatalogueData)
                            controller.dispatch(catalogue.events.DeselectContent)
                        }
                    }
                )
            )
        }
    }

    const selectContent = coords => {
        const newCoords = [coords];
        const listCoords = catalogueData.nodeTree?.arrayToList(newCoords);
        const lwl = new catalogue.events.SelectContent(listCoords);
        controller.dispatch(lwl);
    }

    const handleGoToFirstChild = index => {
        const newCoords = [index];
        const listCoords = catalogueData.nodeTree?.arrayToList(newCoords);
        const lwl = new catalogue.events.SelectContent(listCoords);
        controller.dispatch(lwl);
    };

    const fetchMoreData = () => {
        const lwl = catalogue.events.LoadMoreContent;
        controller.dispatch(lwl)
    }

    const handleFoundData = (event) => {
        if (event.target !== undefined) {
            const lwl = new catalogue.events.Search(event.target.value);
            controller.dispatch(lwl)
        }
    }

    const displayMenu = () => {
        if (showMenu) {
            setShowMenu(false)
        } else {
            setShowMenu(true)
        }
    }

    const handleClickOrder = (name) => {
        setNameOrder(TEXT_NAME_BEGINNING + name)
        const lwl = new catalogue.events.Sort(name);
        controller.dispatch(lwl);
        setShowMenu(false)
    }

    const getTextSort = (id) => {
        return t('catalogue.' + id)
    }

    const handleGoToVideoDetail = (id) => {
        history.push(URL_VIDEODETAIL + "/" + id)
    }

    useEffect(() => {
        if (catalogueData !== null && catalogueData.contents) {
            turnOffLoading()
        }
    }, [catalogueData])


    return (
        <>
            {catalogueData !== null && catalogueData.contents && <div className="Catalogo">
                <div>
                    <div className="Discover">
                        {t('catalogue.discover')}
                    </div>
                    {catalogueData?.selectedNodesDefault && <div className="SelectsContainer">
                        {toArray(catalogueData.nodeTree.rootNodes).length > 0 &&
                        <SectionsMenuComponent sections={catalogueData.nodeTree}
                                               defaultSections={catalogueData?.selectedNodesDefault}
                                               selectNodeParent={selectNodeParent}/> }
                        {toArray(catalogueData.selectedNode.children).length > 0 &&
                        <SubsectionsMenuComponent section={catalogueData.selectedNode}
                                                  defaultSections={catalogueData?.selectedNodesDefault}
                                                  selectNodeChildren={selectNodeChildren}/> }
                        {/*<SearchComponent handleFoundData={handleFoundData}/>*/}
                        {!showNoContent &&
                        <SortMenuComponent displayMenu={displayMenu} nameOrder={nameOrder} showMenu={showMenu}
                                           nameOrderArray={catalogueData.nameOrderArray}
                                           handleClickOrder={handleClickOrder} getTextSort={getTextSort}/>}
                    </div>}
                </div>
                <div className="Categories">
                    {catalogueData.selectedContent && <Breadcrumb breadcrumbs={nodeToBreadcrumb()}/>}
                    <div className="InputCatalogue">
                        {/*<SearchComponent handleFoundData={handleFoundData}/>*/}
                    </div>
                </div>
                {!!catalogueData.contents &&
                    <InfiniteScroll
                        dataLength={catalogueData.contents.length}
                        next={fetchMoreData}
                        hasMore={true}
                        scrollableTarget="Catalogo">
                        <div className="CatalogueVideo">
                            <div className="galleryGrid">
                                {!catalogueData.selectedContent && <ContentComponent contents={catalogueData.contents}
                                                                                     handleGoToVideoDetail={handleGoToVideoDetail}
                                                                                     selectContent={selectContent}
                                                                                     handleGoToFirstChild={handleGoToFirstChild}
                                                                                     changeShowNoContent={changeShowNoContent}
                                                                                     showNoContent={showNoContent}

                                />}
                                {catalogueData.selectedContent && catalogueData.selectedContentChildren &&
                                <SelectedContentComponent content={catalogueData.selectedContentChildren}
                                                          handleGoToVideoDetail={handleGoToVideoDetail}
                                                          contents={catalogueData.contents}
                                                          selectContent={selectContent}
                                                          handleGoToFirstChild={handleGoToFirstChild}
                                                          changeShowNoContent={changeShowNoContent}
                                                          showNoContent={showNoContent}
                                />}
                            </div>
                        </div>
                    </InfiniteScroll>
                }
                {showNoContent && <NoContentComponent/>}
                {error && <AlertNotification/>}
            </div>}
        </>);
}

export default Catalogo;
