import React, { useEffect } from "react";

/**
 * Handle click outside element
 *
 * @param props.elementref Ref to element
 * @param props.callback Function to call when a click outside element is done
 */
export default function HandleClickOutside(props) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (props.elementref?.current && !props.elementref?.current.contains(event.target) && props.callback) {
        props.callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.elementref]);

  return <></>;
}
