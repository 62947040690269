import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import messages_es from "./Resources/i18n/messages_es.json";
import messages_gl from "./Resources/i18n/messages_gl.json";
import messages_en from "./Resources/i18n/messages_en.json";
import messages_jp from "./Resources/i18n/messages_jp.json";
import messages_ca from "./Resources/i18n/messages_ca.json";
import messages_vc from "./Resources/i18n/messages_vc.json";
import messages_pt from "./Resources/i18n/messages_pt.json";

import { MULTITENANT_CONSTANTS, TENANT } from "./Resources/Multitenant/tenantConstants";

const resources = {
  es: {
    translation: messages_es,
  },
  gl: {
    translation: messages_gl,
  },
  en: {
    translation: messages_en,
  },
  jp: {
    translation: messages_jp,
  },
  ca: {
    translation: messages_ca,
  },
  vc: {
    translation: messages_vc,
  },
  pt: {
    translation: messages_pt,
  },
};

const storageLocale = localStorage.getItem("petisgo-locale");

const defaultLocale = MULTITENANT_CONSTANTS[TENANT]?.defaultLocale || MULTITENANT_CONSTANTS.default.defaultLocale;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: storageLocale !== null ? storageLocale : defaultLocale,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
