import React from 'react';
import {useTranslation} from 'react-i18next';
import MobileStepper from '@material-ui/core/MobileStepper';

import {ROL_KID, ROL_TEEN} from './PantallasRexistro';

function CTitor2(props) {
    const {t} = useTranslation();

    return (
        <div className="NameUser">
            <div className="Descricion">
                {t('register.textoconfirmartitor')}
            </div>
            <div className="Stepper">
                {props.datos.rol === ROL_KID &&
                <MobileStepper variant="dots" position="static" className="Stepdot" steps={7} activeStep={3}/>
                }
                {props.datos.rol === ROL_TEEN &&
                <MobileStepper variant="dots" position="static" className="Stepdot" steps={7} activeStep={3}/>
                }
            </div>
            <div className="WizardContainer">
                <div className="ProbaAdulto">
                    12/6
                </div>
            </div>
            <div className="Wizard-Buttons">
                <div className="btnPrevious button-link-secondary" onClick={props.cancelar}>{t('register.cancelar')}</div>
                <div className="btnNext button-link-primary" onClick={props.seguintePantalla}>{t('register.seguinte')}</div>
            </div>
        </div>
    );
}

export default CTitor2;
