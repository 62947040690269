import React, {useState, useEffect} from "react";
import {useTranslation} from 'react-i18next';

import '../../../../Ranking.css';
import iconRanking from '@app/Resources/Images/icon_ranking.svg';
import iconNivel1 from '@app/Resources/Images/icon_nivel1.png';

const UserRankingComponent = ({userRanking, closestAchievement, userName}) => {

    const {t} = useTranslation();

    const [finishedAchievements, setFinishedAchievements] = useState(false);
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        if (!closestAchievement) {
            setFinishedAchievements(true)
        } else {
            setFinishedAchievements(false)
        }

        if (closestAchievement?.pointsNeeded < userRanking?.points) {
            setProgress(99)
        } else if (closestAchievement?.pointsNeeded > userRanking?.points) {
            setProgress(Math.round(userRanking?.points / closestAchievement?.pointsNeeded))
        } else {
            setProgress(null);
        }
    }, [userRanking, closestAchievement]);


    return (
        <>
            <h3 className="User-name">{userName}</h3>
            {userRanking &&
            <>
                <div className="User-info">
                    <img
                        className={"Icono"}
                        src={iconRanking}
                        alt={userName}
                    />
                    <span>{t('ranking.posicion')}</span>
                    <span className="Posicion">{userRanking.position}</span>
                </div>
            </>}
            {closestAchievement &&
            <>
                <div className="User-info">
                    <img className={"Icono"}
                         src={iconNivel1}
                         alt={"icon"}
                    />
                    {finishedAchievements ? <p className={"Logro-finished"}>¡No tienes más logros disponibles!</p> :
                        <div className={"User-achievement-text"}>
                            <span className={"User-next-achievement"}>{t('ranking.proximo_logro')}</span>
                            <span className={"User-next-achievement-name"}>{closestAchievement.name}</span>
                            {progress && <div className={"Logro-progress-bar-total"}>
                                <div className={"Logro-progress-bar-user"} style={{width: `${progress}%`}}/>
                            </div>}
                        </div>}
                </div>
            </>}
        </>
    )
};

export default UserRankingComponent;