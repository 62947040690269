import React, {useState, useEffect} from "react";
import '../Catalogo.css';
import {toArray} from '@components/Kotlin/KotlinUtils';
import {Col} from "reactstrap";

const SubsectionsMenuComponent = ({section, defaultSections, selectNodeChildren}) => {

    const [subsection, setSubsection] = useState(defaultSections[1])
    const [displayMenu, setDisplayMenu] = useState(false);

    const handleChangeSubSection = value => {
        setSubsection(value)
        selectNodeChildren(value);
    }

    const handleDisplayMenu = () => {
        setDisplayMenu(displayMenu => !displayMenu)
    }

    useEffect(() => {
        if(defaultSections[1] !== subsection){
            setSubsection(defaultSections[1])
        }
    }, [defaultSections])

    return (
<>
    {section && toArray(section.children).length > 0 && <Col xs={6} sm={3} lg={2} className="OrderMenuContainer">
        <div className="OrderMenu">
            <div className="MenuCatalogueContainer" onClick={handleDisplayMenu}>
                                    <span className="MenuCatalogue sectionsMenuTitle">
                                        {subsection}
                                    </span>
                <span className={"IconMenuCatalogue"}>
                                        <ion-icon name="chevron-down-outline"/>
                                    </span>
            </div>
            {displayMenu && <div className="MenuDesplegableOrder">
                {toArray(section.children).map(n =>
                    <div type="button" className="MenuSubsectionOrder"
                         key={n.nodeName ? n.nodeName : n.value?.nodeName}
                         onClick={() => handleChangeSubSection(n.nodeName ? n.nodeName : n.value?.nodeName)}>
                        <span className="button-text">{n.value?.nodeName}</span>
                    </div>)}
            </div>}
        </div>

    </Col>}
    </>
    )
};

export default SubsectionsMenuComponent;