import {useState, useEffect} from 'react';
import useWindowDimensions from '@components/DOM/Screen/ScreenDimensions';

//Tamaño carrusel
export const responsive = {
    xxldesktop: {
        breakpoint: {max: 3000, min: 1960},
        items: 5,
        slidesToSlide: 4,
    },
    xldesktop: {
        breakpoint: {max: 1960, min: 1480},
        items: 4,
        slidesToSlide: 3,
    },
    desktop: {
        breakpoint: {max: 1480, min: 1130},
        items: 3,
        slidesToSlide: 2,
    },
    tablet: {
        breakpoint: {max: 1130, min: 900},
        items: 3,
        slidesToSlide: 2,
    },
    xlmobile: {
        breakpoint: {max: 900, min: 550},
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: {max: 550, min: 0},
        items: 1,
        slidesToSlide: 1,
    }
};


export const responsivelarge = {
    xxldesktop: {
        breakpoint: {max: 3000, min: 1960},
        items: 5,
        slidesToSlide: 4,
    },
    xldesktop: {
        breakpoint: {max: 1960, min: 1480},
        items: 4,
        slidesToSlide: 3,
    },
    desktop: {
        breakpoint: {max: 1480, min: 1130},
        items: 3,
        slidesToSlide: 2,
    },
    tablet: {
        breakpoint: {max: 1130, min: 900},
        items: 3,
        slidesToSlide: 2,
    },
    xlmobile: {
        breakpoint: {max: 900, min: 550},
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: {max: 550, min: 0},
        items: 1,
        slidesToSlide: 1,
    }
};


export const responsiveCircle = {
    xxldesktop: {
        breakpoint: {max: 3000, min: 1960},
        items: 10,
        slidesToSlide: 5,
    },
    xldesktop: {
        breakpoint: {max: 1960, min: 1480},
        items: 8,
        slidesToSlide: 4,
    },
    desktop: {
        breakpoint: {max: 1480, min: 1130},
        items: 6,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: {max: 1130, min: 900},
        items: 6,
        slidesToSlide: 3,
    },
    xlmobile: {
        breakpoint: {max: 900, min: 550},
        items: 5,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: {max: 550, min: 0},
        items: 2,
        slidesToSlide: 1,
    }
};

export const responsiveGroup = {
    xxldesktop: {
        breakpoint: {max: 3000, min: 1960},
        items: 6,
        slidesToSlide: 4,
    },
    xldesktop: {
        breakpoint: {max: 1960, min: 1480},
        items: 6,
        slidesToSlide: 3,
    },
    desktop: {
        breakpoint: {max: 1480, min: 1130},
        items: 6,
        slidesToSlide: 2,
    },
    tablet: {
        breakpoint: {max: 1130, min: 900},
        items: 4,
        slidesToSlide: 2,
    },
    xlmobile: {
        breakpoint: {max: 900, min: 550},
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: {max: 550, min: 0},
        items: 1,
        slidesToSlide: 1,
    }
};



export default function getNumItems(islarge) {
    var r = responsive;
    if(islarge){
        r = responsivelarge;
    }
    const {width} = useWindowDimensions();
    const [numItems, setNumItems] = useState(0);

    useEffect(() => {
        if (r.mobile.breakpoint.max > width) {
            setNumItems(r.mobile.items)
        } else if (r.xlmobile.breakpoint.max > width) {
            setNumItems(r.xlmobile.items)
        } else if (r.tablet.breakpoint.max > width) {
            setNumItems(r.tablet.items)
        } else if (r.desktop.breakpoint.max > width) {
            setNumItems(r.desktop.items)
        } else if (r.xldesktop.breakpoint.max > width) {
            setNumItems(r.xldesktop.items)
        } else {
            setNumItems(r.xxldesktop.items)
        }
    }, [width]);

    return {numItems: numItems};
}
