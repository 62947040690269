import React from "react";
import {Row, Col} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import '../../Ranking.css';

import {TYPE_REWARD_CERT, TYPE_REWARD_HOOK} from "../../../../../../app/src/Utils/Constants";


import achievementIcon from '@app/Resources/Images/Achievement.png';
import crownIcon from '@app/Resources/Images/Reward_crown.svg';
import DiplomaIconComponent from "../../components/DiplomaIconComponent";

const AchievementComponent = ({renderTitle, achievements, rewards}) => {

    const {t} = useTranslation();

    const routeChange = (path) => {
        const win = window.open(path, "_blank");
        win.focus();
    }

    const contentByRewardType = (reward) => {
        switch (reward.type) {
            case TYPE_REWARD_CERT:
                return <button onClick={()=>routeChange(reward.value)}
                               className="PdfButton button-focused">{t("global.button.download")}</button>
            case TYPE_REWARD_HOOK:
                return <button onClick={() => routeChange(reward.value)}
                               className="PdfButton button-focused">{t("global.button.watch")}</button>
            default:
                return '';
        }
    }

const orderRewardsAndAchievements = [...achievements, ...rewards].sort(function(a, b) {
    const fechaA = !!a?.accomplishmentTimeMs ? a?.accomplishmentTimeMs : a?.achievement?.accomplishmentTimeMs
    const fechaB = !!b?.accomplishmentTimeMs ? b?.accomplishmentTimeMs : b?.achievement?.accomplishmentTimeMs
return fechaB - fechaA;
})


    return (
        <div className="LogrosContainer">
            <Row>
                <Col xs="12">
                    {!renderTitle && <p className="Logros-title">{t('ranking.mis_logros')}</p>}
                </Col>
                <Col xs="12" className={"RewardList"}>
                        {orderRewardsAndAchievements && orderRewardsAndAchievements.map((e, index) => !e.achievement ?
                        <Row className="List-user-logros" key={'achievement' + index}>
                            <Col xs="2">
                                <img className={"Icono"} src={achievementIcon} alt={"Achievement-icon"}/>
                            </Col>
                            <Col xs="10">
                                <p className="Titulo-logro">{e.name}</p>
                                <p className="Descripcion-logro">{e.description}</p>
                            </Col>
                        </Row> : <Row className="List-user-logros" key={'reward' + index}>
                        <Col xs="2">
                            {e.type === TYPE_REWARD_CERT ?
                                <DiplomaIconComponent/> :
                                <img className={"Icono"} src={e.value || crownIcon} alt={"Reward-icon"}/>}
                        </Col>
                        <Col xs="5" md="6">
                            <p className="Titulo-logro">{e.name}</p>
                            <p className="Descripcion-logro">{e.description}</p>
                        </Col>
                        <Col xs="5" md="4" className={"RewardButtonContainer"}>
                            {contentByRewardType(e)}
                        </Col>
                    </Row> )}
                </Col>
            </Row>
        </div>
    )
};

export default AchievementComponent;