import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import moment from "moment";
import 'moment/locale/gl';

import {User} from './User';
import {getRoleStr, ROL_KID, ROL_TEEN, ROL_ADULT} from "@user/Pages/Register/PantallasRexistro";
import avatarDefault from "@resources/Images/avatardefault.png";
import {user} from '@core'
import {DATA_LOADING_STATUS_LOADED} from "../../../app/src/Utils/Constants";
import {compareUserProfileObjects} from "../../../app/src/Utils/utils";
import {useKeycloak} from '@react-keycloak/web';
import useOutput from "../../../app/src/Utils/Hooks/useOutput";


export function UserProvider({children}) {

    let con;
    let binding;

    const {keycloak} = useKeycloak();
    const outputFn = useOutput();

    const [controller, setController] = useState(null);
    const [isAnimationOn, setIsAnimationOn] = useState(false);
    const [userData, setUserData] = useState(null);

    const reloadUser = () => {
        if (controller) {
            controller.unbind();
        }

        con = new user.controller();
        binding = con.bind(model, o => outputFn(o, output, user));
        setController(con);

        setTimeout(() => {
            setIsAnimationOn(false);
        }, 3000);
    }

    useEffect(() => {
        reloadUser()

        return () => {
            con.unbind(binding);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const model = (o) => {
        const formattedModel = {};

        formattedModel.avatar = o.avatar;
        formattedModel.username = o.username;
        formattedModel.points = o.ranking?.points;
        formattedModel.loading = o.loadingUser.name$;

        if (!o.avatar) {
            o.avatar = {
                url: avatarDefault
            }
            formattedModel.avatar = o.avatar;
        }

        $('#root').removeClass(ROL_KID);
        $('#root').removeClass(ROL_TEEN);
        $('#root').removeClass(ROL_ADULT);
        if (o.birthday) {
            let dbirth = moment(o.birthday, 'YYYY-MM-DD').toDate();
            let ageDifMs = Date.now() - dbirth.getTime();
            let ageDate = new Date(ageDifMs);
            let idade = Math.abs(ageDate.getUTCFullYear() - 1970);

            if (o.availableRoles) {
                let rol;
                let it = o.availableRoles.iterator();
                while (it.hasNext()) {
                    let a = it.next();
                    if ((!a.minAge || a.minAge <= idade) && (!a.maxAge || a.maxAge >= idade)) {
                        rol = a;
                    }
                }

                formattedModel.rol = rol;

                let rolStr = getRoleStr(rol);
                $('#root').addClass(rolStr);
            }
        }


        if (!keycloak.authenticated) {
            setUserData(formattedModel)
        }

        if (formattedModel.loading === DATA_LOADING_STATUS_LOADED && compareUserProfileObjects(userData, formattedModel)) {
            setUserData(formattedModel)
        }

        if (o?.ranking?.points && (o?.ranking?.points > userData?.points || !userData?.points)) {
            setTimeout(() => {
                setIsAnimationOn(true);
            }, 2000);
        }
    }

    const output = () => {
    };

    return <User.Provider value={{
        rol: userData?.rol,
        avatar: userData?.avatar,
        reloadUser: reloadUser,
        userName: userData?.username,
        points: userData?.points,
        isAnimationOn: isAnimationOn
    }}>
        {children}
    </User.Provider>
};
