import React, { useEffect, useState } from "react";
import { notifications, interops } from "@core";
import { Notifications } from "./Notifications";

export function NotificationsProvider(props) {
  let conNotifications;
  let bindingNotifications;

  const [controller, setController] = useState(null);

  const [notificationsData, setNotificationsData] = useState(null);

  const reloadNotifications = () => {
    if (controller) {
      controller.unbind();
    }
    conNotifications = new notifications.controller();
    bindingNotifications = conNotifications.bind(model, output);
    setController(conNotifications);
  };

  useEffect(() => {
    reloadNotifications();

    return () => {
      conNotifications.unbind(bindingNotifications);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const model = (o) => {
    setNotificationsData(interops.toJsArray(o.notifications));
  };

  const output = () => {};

  const deleteNotification = (id) => {
    const ev = new notifications.events.DeleteNotification(id);
    controller.dispatch(ev);
  };

  const readNotification = (id) => {
    const ev = new notifications.events.ReadNotification(id);
    controller.dispatch(ev);
  };

  const { children } = props;

  return (
    <Notifications.Provider
      value={{
        notifications: notificationsData,
        reloadNotifications: reloadNotifications,
        deleteNotification: deleteNotification,
        readNotification: readNotification,
      }}
    >
      {children}
    </Notifications.Provider>
  );
}
