/*
Here is defined every component to interact with the core implementations

Each object can define the following properties:
    events, with the events that the core may receive to start operations
    outputs, with the notifications that the core can may send to the web
    model, with the state information that the core exposes to the web
    controller, as the component through which the connection with the core is made

Check the related classes section on each object to see the reference to the Kotlin implementation
with detailed doc information of each component
*/

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/user/splash/view/SplashView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/user/splash/SplashController.kt
var splash = {
    events: window.core.es.cinfo.tiivii.user.splash.view.SplashView.Event,
    outputs: window.core.es.cinfo.tiivii.user.splash.view.SplashView.Output,
    controller: window.core.es.cinfo.tiivii.user.splash.SplashController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/user/legal/view/LegalView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/user/legal/LegalController.kt
var legal = {
    events: window.core.es.cinfo.tiivii.user.legal.view.LegalView.Event,
    outputs: window.core.es.cinfo.tiivii.user.legal.view.LegalView.Output,
    controller: window.core.es.cinfo.tiivii.user.legal.LegalController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/user/signup/view/SignupView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/user/signup/SignupController.kt
var signup = {
    events: window.core.es.cinfo.tiivii.user.signup.view.SignupView.Event,
    outputs: window.core.es.cinfo.tiivii.user.signup.view.SignupView.Output,
    controller: window.core.es.cinfo.tiivii.user.signup.SignupController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/user/profile/view/UserView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/user/profile/UserController.kt
var user = {
    events: window.core.es.cinfo.tiivii.user.profile.view.UserView.Event,
    outputs: window.core.es.cinfo.tiivii.user.profile.view.UserView.Output,
    controller: window.core.es.cinfo.tiivii.user.profile.UserController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/playback/view/PlaybackView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/playback/PlaybackController.kt
var playback = {
    events: window.core.es.cinfo.tiivii.core.features.playback.view.PlaybackView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.playback.view.PlaybackView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.playback.PlaybackController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/home/view/HomeView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/home/HomeController.kt
var home = {
    events: window.core.es.cinfo.tiivii.home.view.HomeView.Event,
    outputs: window.core.es.cinfo.tiivii.home.view.HomeView.Output,
    controller: window.core.es.cinfo.tiivii.home.HomeController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/nav/menu/view/MenuView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/nav/menu/MenuController.kt
var menu = {
    events: window.core.es.cinfo.tiivii.nav.menu.view.MenuView.Event,
    outputs: window.core.es.cinfo.tiivii.nav.menu.view.MenuView.Output,
    controller: window.core.es.cinfo.tiivii.nav.menu.MenuController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/search/view/SearchView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/search/SearchController.kt
var search = {
    events: window.core.es.cinfo.tiivii.search.view.SearchView.Event,
    outputs: window.core.es.cinfo.tiivii.search.view.SearchView.Output,
    controller: window.core.es.cinfo.tiivii.search.SearchController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/detail/view/DetailView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/detail/DetailController.kt
var detail = {
    events: window.core.es.cinfo.tiivii.core.features.detail.view.DetailView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.detail.view.DetailView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.detail.DetailController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/footer/view/FooterView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/footer/FooterController.kt
var footer = {
    events: window.core.es.cinfo.tiivii.footer.view.FooterView.Event,
    outputs: window.core.es.cinfo.tiivii.footer.view.FooterView.Output,
    controller: window.core.es.cinfo.tiivii.footer.FooterController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/catalogue/view/CatalogueView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/catalogue/CatalogueController.kt
var catalogue = {
    events: window.core.es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.catalogue.view.CatalogueView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.catalogue.CatalogueController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/content/view/ContentView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/content/ContentController.kt
var content = {
    events: window.core.es.cinfo.tiivii.core.features.content.view.ContentView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.content.view.ContentView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.content.ContentController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/ranking/view/ContentView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/ranking/ContentController.kt
var ranking = {
    events: window.core.es.cinfo.tiivii.core.features.ranking.view.RankingView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.ranking.view.RankingView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.ranking.RankingController
}

// Related classes
// - core/src/commonMain/kotlin/es/cinfo/tiivii/core/features/notifications/view/NotificationsView.kt
// - core/src/jsMain/kotlin/es/cinfo/tiivii/core/features/notifications/NotificationsController.kt
var notifications = {
    events: window.core.es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Event,
    outputs: window.core.es.cinfo.tiivii.core.features.notifications.view.NotificationsView.Output,
    controller: window.core.es.cinfo.tiivii.core.features.notifications.NotificationsController
}

var interops = {
    toKotlinList: function toKotlinList(array) {
        return window.core.es.cinfo.tiivii.core.util.Interops.toKotlinList(array)
    },
    toKotlinSet: function toKotlinList(array) {
        return window.core.es.cinfo.tiivii.core.util.Interops.toKotlinSet(array)
    },
    toJsArray: function toJsArray(kotlinList) {
        return window.core.es.cinfo.tiivii.core.util.Interops.toJsArray(kotlinList)
    },
    getKotlinName: function getKotlinName(output) {
        return window.core.es.cinfo.tiivii.core.util.Interops.getKotlinName(output)
    }
}

var coreBuild = window.core.es.cinfo.tiivii.core.Core.getCoreBuild()

export {
    splash,
    legal,
    signup,
    user,
    playback,
    home,
    menu,
    search,
    detail,
    footer,
    catalogue,
    content,
    ranking,
    notifications,
    interops,
    coreBuild
}