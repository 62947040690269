import React, {useState, useEffect} from 'react';

import {SearchContext} from './SearchContext';

export function SearchProvider(props) {
    const [text, setText] = useState(null);
    const [listWidgets, setListWidgets] = useState(null);
    const [error, setError] = useState(null);

    const updateText = (t) => {
        setText(t)
    }

    const updateListWidgets = (w) => {
        setListWidgets(w)
    }

    const updateError = (e) => {
        setError(e)
    }

    const {children} = props;

    return (
        <SearchContext.Provider value={{
            text: text,
            listWidgets: listWidgets,
            error: error,
            updateText: updateText,
            updateWidgets: updateListWidgets,
            updateError: updateError
        }}>
            {children}
        </SearchContext.Provider>
    );
}