import React, { useRef, useState } from "react";
import "./InfoPanel.css";

/**
 * Engade un botón que mostra/oculta un panel ao premelo.
 *
 * Usa as seguintes propiedades, todas son opcionales:
 *
 *      <b>classButton</b>: clases css que se aplican ao boton
 *
 *      <b>classPanel</b>: clases css que se aplican ao panel
 *
 *      <b>classMessage</b>: clases css que se aplican ao mensaxe do panel
 *
 *      <b>classFondo</b>: clases css que se aplican ao resto da pantalla cando se mostra o panel
 *
 *      <b>icon</b>: clases css do icono que se mostra dentro do botón (ex: "fa fa-info-circle")
 *
 *      <b>message</b>: mensaxe que se mostra no panel
 *
 *      <b>hideonclick</b>: oculta o panel ao pinchar fora del, por defecto false
 *
 *     <b>No interior do compoñente pode pasarse o contido do panel.</b>
 *     <br><i> Se hai mensaxe mais contido, móstrase primeiro a mensaxe e despois o contido.</i>
 *
 *  <hr/>
 * Exemplos de uso:
 *    1. Mostra un panel sen mensaxe, tendo de contido elementos html
 *
 *      <InfoPanel classButton={"passwordInfo"} classPanel={"Enumeracion"} icon={"fa fa-info-circle"}>
 *        <span className={"Linea"}>{t('register.infopassword1')}</span>
 *        <span className={"Linea"}>{t('register.infopassword2')}</span>
 *        <span className={"Linea"}>{t('register.infopassword3')}</span>
 *        <span className={"Sublinea"}>{t('register.infopassword4')}</span>
 *      </InfoPanel>
 *
 *
 *    2. Mostra un panel con mensaxe, sen contido, e que se oculta ao premer fora do panel
 *
 *      <InfoPanel classButton={"passwordInfo"} classPanel={"Enumeracion"} classFondo={"Fondo"} classMessage={"passwordMessage"}
 *          icon={"fa fa-info-circle"} message={t('texto.password')} hideonclick={true}/>
 *
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function InfoPanel(props) {
  const [mostrar, setMostrar] = useState(false);
  const { children } = props;
  const refPanel = useRef(null);

  const toogle = () => {
    if (mostrar) {
      setMostrar(false);
    } else {
      setMostrar(true);
      //scrollToRef(refPanel)
    }
  };

  const hide = () => {
    setMostrar(false);
  };

  return (
    <>
      {mostrar && (
        <div
          className={
            "FondoInfoPanel " +
            (props.hideonclick ? "" : "FondoAtravesable ") +
            (props.classFondo ? props.classFondo : "")
          }
          onClick={props.hideonclick ? hide : ""}
        />
      )}
      <div className={"InfoPanelButton " + (props.classButton ? props.classButton : "")}>
        <i className={props.icon ? props.icon : ""} onClick={toogle} />
      </div>
      <div
        ref={refPanel}
        className={"InfoPanel " + (props.classPanel ? props.classPanel : "") + (mostrar ? " Mostrar" : "")}
      >
        {props.message ? (
          <span className={"InfoPanelMessage " + (props.classMessage ? props.classMessage : "")}>props.message</span>
        ) : (
          <></>
        )}
        {children}
      </div>
    </>
  );
}
